import React, { useState } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import logo from "../../images/logo.png";
import logoIcon from "../../images/taco_twist_icon.png";
import { Link } from "react-router-dom";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";
import style from "./orderPopup.module.css";
import { MdPayment } from "react-icons/md";
import { IoIosClose } from "react-icons/io";

const OrderPopup = () => {
  const [topRightModal, setTopRightModal] = useState(false);
  const toggleShow = () => setTopRightModal(!topRightModal);
  return (
    <>
      <div className="headingIcon">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li
            className="nav-item"
            onClick={() => {
              toggleShow();
            }}
          >
            <MdPayment /> PAY NOW
          </li>
        </ul>
      </div>
      <MDBModal
        animationDirection="right"
        show={topRightModal}
        tabIndex="-1"
        setShow={setTopRightModal}
      >
        <MDBModalDialog position="top-right" side>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                <Link to="/">
                  <img src={logo} alt="logo" width={250} onClick={toggleShow} />
                </Link>
              </MDBModalTitle>

              <div className={style.closeCart} onClick={toggleShow}>
                <IoIosClose />
              </div>
            </MDBModalHeader>
            <div>
              <MDBModalBody>
                <div>
                  <p className="speciality">HURRAY!! YOUR ORDER IS PLACED </p>
                  <p className={style.aboutCart}>
                    Start an order for yourself, or you and your friends.
                  </p>
                </div>
              </MDBModalBody>
              <div className={style.cartButton}>
                <img src={logoIcon} alt="logo" width={150} />
                <Link to="/">
                  <button
                    type="button"
                    className={style.orderButton}
                    onClick={toggleShow}
                  >
                    Start Your Order
                  </button>
                </Link>
              </div>
            </div>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default OrderPopup;
