import React, { Fragment } from 'react'
import Navbar from '../components/header/header.component'
import Footer from '../components/Footer/Footer.component'
import bannerimage from "../images/story_topimg.jpg";
import OurStory from '../components/Story/Story.component';
import Helmet from "helmet";

const StoryPage = () => {
    return (
        <Fragment>


            <Helmet>
                <title>Taco Twist | Taco Place Near Me</title>
                <meta name="description" content=" Satisfy your taco cravings with Taco Twist! Find the best taco place near you at tacotwist.com. Indulge in a delicious variety of taco options for an unforgettable dining experience.
" />


            </Helmet>

            <Navbar />
            <img src={bannerimage} alt='bannerimage' />
            <OurStory />
            <Footer />
        </Fragment>
    )
}

export default StoryPage;
