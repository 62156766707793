import React, { Fragment, useState } from "react";
import images from "../../images/chefimage.jpg";
import "./profile.css";

const Profile = () => {
  const [order, setOrder] = useState(true);

  const getOrderDetails = () => {
    setOrder(false);
    console.log("getOrderDetails");
  };

  const getPersonalDetails = () => {
    setOrder(true);
    console.log("getOrderDetails");
  };

  return (
    <Fragment>
      <div className="order-fluid">
        <div className="container emp-profile">
          <div className="row">
            <div className="col-md-4">
              <div className="profile-img">
                <img src={images} alt="" />
                <div className="file btn btn-lg btn-primary">
                  Change Photo
                  <input type="file" name="file" value="" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="profile-head">
                <h5>John Doe</h5>
                <h6>John1234@gmail.com</h6>
                <div className=" edit-Button">
                  <input
                    type="submit"
                    className="profile-edit-btn"
                    name="btnAddMore"
                    value="Edit Profile"
                  />
                </div>

                <p className="proile-rating"> </p>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <p
                      className="nav-link col-ft pe-0 ps-0"
                      id="home-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      onClick={getPersonalDetails}
                    >
                      About
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className="nav-link col-ft"
                      id="profile-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={getOrderDetails}
                    >
                      Orders
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-11">
                <div className="tab-content profile-tab" id="myTabContent">
                  {order ? (
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <label for="Name">Name</label>
                        </div>
                        <div className="col-md-6">
                          <p>John Doe</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label for="Email">Email</label>
                        </div>
                        <div className="col-md-6">
                          <p>John1234@gmail.com</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label for="Phone">Phone</label>
                        </div>
                        <div className="col-md-6">
                          <p>9876543210</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label for="Password">Date Of Birth</label>
                        </div>
                        <div className="col-md-6">
                          <p>22-Feb-2001</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="tab-pane fade show active  "
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="overFlow-y">
                        <div className="row order-details">
                          <div className="col-md-3">
                            <h5>Pizza Name</h5>
                          </div>
                          <div className="col-md-3">
                            <h5>Price</h5>
                          </div>

                          <div className="col-md-3">
                            <h5>Date</h5>
                          </div>

                          <div className="col-md-3">
                            <h5>Status</h5>
                          </div>
                        </div>
                        <div className="row order-details">
                          <div className="col-md-3">
                            <p>Fish Pie</p>
                          </div>
                          <div className="col-md-3">
                            <p>$30</p>
                          </div>

                          <div className="col-md-3">
                            <p>25/03/2023</p>
                          </div>

                          <div className="col-md-3">
                            <p>Delivered</p>
                          </div>
                        </div>
                        <div className="row order-details">
                          <div className="col-md-3">
                            <p>Escovitch Fish</p>
                          </div>
                          <div className="col-md-3">
                            <p>$10</p>
                          </div>

                          <div className="col-md-3">
                            <p>05/04/2023</p>
                          </div>
                          <div className="col-md-3">
                            <p>Delivered</p>
                          </div>
                        </div>
                        <div className="row order-details">
                          <div className="col-md-3">
                            <p>Fish fofos</p>
                          </div>
                          <div className="col-md-3">
                            <p>$25</p>
                          </div>
                          <div className="col-md-3">
                            <p>22/04/2023</p>
                          </div>
                          <div className="col-md-3">
                            <p>Delivered</p>
                          </div>
                        </div>

                        <div className="row order-details">
                          <div className="col-md-3">
                            <p>Kedgeree</p>
                          </div>
                          <div className="col-md-3">
                            <p>$56</p>
                          </div>
                          <div className="col-md-3">
                            <p>07/05/2023</p>
                          </div>
                          <div className="col-md-3">
                            <p>Delivered</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Profile;
