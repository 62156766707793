import React from "react";
import Header from "../components/header/header.component.js";
import Footer from "../components/Footer/Footer.component.js";
import LocationComponents from "../components/Location/location.component.js";

const Location = () => {
  return (
    <>
      <Header />
      <LocationComponents />
      <Footer />
    </>
  );
};

export default Location;
