import { createContext, useState } from "react";
import { useEffect } from "react";
import { getAlldataList } from "../services/api/api.service";

export const GlobalData = createContext(null);

const GlobalDataProvider = (props) => {
  const [useData, setuseData] = useState(null);
  const [foodData, setFoodData] = useState([]);
  const [mealPrice, setMealPrice] = useState([]);
  const [mealList, setMealList] = useState([]);
  const [cart, setCart] = useState([]);
  useEffect(() => {
    getAlldataList().then((e) => {
      setFoodData(e.data.meals);
    });
  }, []);
  const value = {
    
    useData: useData,
    setuseData: setuseData,
    foodData: foodData,
    setFoodData: setFoodData,
    mealPrice: mealPrice,
    setMealPrice: setMealPrice,
    mealList: mealList,
    setMealList: setMealList,
    cart: cart,
    setCart: setCart,
  };

  return (
    <>
      <GlobalData.Provider value={value} {...props} />
    </>
  );
};
export default GlobalDataProvider;
