import React, { useRef, useState } from "react";
import { allImages } from "../../images";
import style from "./Menusection.module.css";
import { sides } from "../../dummyJson/sides";
import { wings } from "../../dummyJson/wings";
import { drinks } from "../../dummyJson/drinks";
import { flavor } from "../../dummyJson/flavor";
import { topping } from "../../dummyJson/topping";
import SelectionPopup from "./selectionPopup";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import LocationPopup from "./locationpopup";

import { HashLink } from "react-router-hash-link";
import Counter from "./counterValue";

const MenuSection = () => {
  const navigate = useNavigate();

  const [mealList, setMealList] = useState([]);
  const [addOnsDetials, setAddOnsDetails] = useState([]);

  const getCheckedValue = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    //checks  selected
    if (checked) {
      setMealList([...mealList, JSON.parse(value)]);
    }
    // checks  unselected
    else {
      setMealList(mealList.filter((e) => JSON.stringify(e) !== value));
    }
  };

  //add to cart details

  const cartFunc = () => {
    let finalList = {
      mealList: mealList,
      addOns: addOnsDetials,
    };
    const dataFromLocal = secureLocalStorage.getItem("allCartData") || [];
    if (dataFromLocal === null) {
      secureLocalStorage.setItem("allCartData", [finalList]);
    } else {
      const finalData = [...dataFromLocal, finalList];
      secureLocalStorage.setItem("allCartData", finalData);
    }
    setMealList([]);

    navigate("/");
  };

  // for addons Details

  const childStateRef = useRef();

  const getValueFromCounter = (e) => {
    //  alert(`2 quantity added for name`);
    const quantityOfMeal = childStateRef.current.getChildCount();

    const addOnsValue = {
      quantityOfMeal: quantityOfMeal,
      mealList: e,
    };

    setAddOnsDetails([...addOnsDetials, addOnsValue]);
    alert(`${quantityOfMeal} quantity  added of ${e.name}`);

    //  console.log(addOnsValue, "========addOnsValue ===========");
  };

  //  console.log(addOnsDetials, " addons ==============");

  return (
    <>
      <div className={style.mainMenu}>
        <div class="d-flex justify-content-around">
          <div class={style.mealList}>
            {/* <LocationPopup /> */}

            <img
              className={style.menuDeal}
              src={allImages.naantacosImages}
              alt="naantacosImages"
            />
            <div className={style.mealsheading}>
              <h3> Naan</h3> <h3>Tacos (2pc)  </h3>
            </div>

          </div>

          <div class={style.mealList}>
            <img
              className={style.menuDeal}
              src={allImages.streettacosImages}
              alt="streettacosImages"
            />
            <div className={style.mealsheading}>
              <h3>Street</h3>
              <h3>Tacos(2pc)</h3>
            </div>
          </div>
          <div class={style.mealList}>
            <img
              className={style.menuDeal}
              src={allImages.chalupaImages}
              alt="chalupaImages"
            />
            <div className={style.mealsheading}>
              <h3>Chalupa</h3>
              <h3>(2pc)</h3>
            </div>
          </div>
          <div class={style.mealList}>
            <img
              className={style.menuDeal}
              src={allImages.tawatartosImages}
              alt="tawatartosImages"
            />
            <div className={style.mealsheading}>
              <h3>Tawa</h3>
              <h3>Tortas</h3>
            </div>
          </div>

          <div class={style.mealList}>
            <img
              className={style.menuDeal}
              src={allImages.burritoImages}
              alt="burritoImages"
            />
            <div className={style.mealsheading}>
              <h3>Burrito</h3>
            </div>
          </div>
        </div>
      </div>
      <div className={style.desktopVersion}>
        <div className={style.buildMeal}>
          <div className={style.buildMealImage}>
            <img
              src={allImages.customizeOwnTacoImages}
              alt="customizeOwnTacoImages"
            />
          </div>
          <div className={style.buildMealHead}>
            <h2>
              BUILD YOUR <br />
              NANTACOS IN | 5 STEPS
            </h2>

            <p>LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND</p>
          </div>
        </div>

        <div className="container mt-5">
          {/* entree section */}
          <div className={style.toppingSection}>
            <div className={style.entreeHead}>
              <div className={style.entreeText}>
                <h2>CHOOSE YOUR ENTREE</h2>
                {/* <p>$6.99 each</p> */}
              </div>
            </div>

            <div className={style.entreeMealList}>

              <div className={style.entreeMealsDetail}>
                <img src={allImages.naanmenuImages} alt="naanmenuImages" />
                <h4> Naan Tacos</h4>
                <LocationPopup />


                {/* <label>
                  <input
                    type="checkbox"
                    name="naan"
                    value={JSON.stringify({
                      name: "Naantaco",
                      value: 6.99,
                      type: "entree",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img
                  src={allImages.naanBurritoImages}
                  alt="naanBurritoImages"
                />

                <h4>Naan Burrito</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Burrito"
                    value={JSON.stringify({
                      name: "Naan Burrito",
                      value: 6.99,
                      type: "entree",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.tortosImages} alt="tortosImages" />

                <h4> Tortas</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Tortas"
                    value={JSON.stringify({
                      name: "Tortas",
                      value: 6.99,
                      type: "entree",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img
                  src={allImages.chalupaMenuImages}
                  alt="chalupaMenuImages"
                />

                <h4> Chalupa</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Chalupa"
                    value={JSON.stringify({
                      name: "Chalupa",
                      value: 6.99,
                      type: "entree",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.bowlImages} alt="bowlImages" />

                <h4> Bowl</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Bowl"
                    value={JSON.stringify({
                      name: "Bowl",
                      value: 6.99,
                      type: "entree",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
            </div>
          </div>
          {/* protein section */}
          <div className={style.toppingSection}>
            <div className={style.entreeHead}>
              <div className={style.entreeText}>
                <h2>CHOOSE YOUR PROTEIN</h2>
                {/* <p>(Additional Protein $2.00) $ 6.99 each </p> */}
              </div>
            </div>

            <div className={style.entreeMealList}>
              <div className={style.entreeMealsDetail}>
                <img
                  src={allImages.spicedChickenImage}
                  alt="spicedChickenImage"
                />

                <h4> Spiced Chicken</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Spiced-Chicken"
                    value={JSON.stringify({
                      name: "Spiced Chicken",
                      value: 6.99,
                      type: "protein",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img
                  src={allImages.malaliTikkaChickenImage}
                  alt="malaliTikkaChickenImage"
                />

                <h4>Malai Tikka Chicken</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Malai-Tikka-Chicken"
                    value={JSON.stringify({
                      name: "Malai-Tikka-Chicken",
                      value: 6.99,
                      type: "protein",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img
                  src={allImages.grilledChikenImage}
                  alt="grilledChikenImage"
                />

                <h4> Grilled Chicken</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Grilled-Chicken"
                    value={JSON.stringify({
                      name: "Grilled-Chicken",
                      value: 6.99,
                      type: "protein",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              {/* <div className={style.entreeMealsDetail}>
                <img
                  src={allImages.plantBasedChikenImage}
                  alt="plantBasedChikenImage"
                />

                <h4> Plant-Based Chicken</h4> */}
              {/* <label>
                  <input
                    type="checkbox"
                    name=" Plant-Based-Chicken"
                    value={JSON.stringify({
                      name: "Plant-Based Chicken",
                      value: 6.99,
                      type: "protein",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              {/* </div> */}
              <div className={style.entreeMealsDetail}>
                <img src={allImages.greekLambImage} alt="greekLambImage" />

                <h4> Greek Lamb</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name=" Greek-Lamb"
                    value={JSON.stringify({
                      name: "Greek-Lamb",
                      value: 6.99,
                      type: "protein",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.paneerTikkaImage} alt="paneerTikkaImage" />

                <h4> Paneer Tikka</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name=" Paneer-Tikka"
                    value={JSON.stringify({
                      name: "Paneer Tikka",
                      value: 6.99,
                      type: "protein",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>

              <div className={style.entreeMealsDetail}>
                <img src={allImages.aaloTikkaImages} alt="aaloTikkaImages" />

                <h4>Aloo Tikki</h4>  <LocationPopup />
                {/* <label>
                  <input
                    type="checkbox"
                    name="Aloo-Tikki"
                    value={JSON.stringify({
                      name: "Aloo Tikki",
                      value: 6.99,
                      type: "protein",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
            </div>
          </div>
          {/* cheese section */}
          <div className={style.toppingSection}>
            <div className={style.entreeHead}>
              <div className={style.entreeText}>
                <h2>CHOOSE YOUR CHEESE</h2>
                {/* <p> $ 6.99</p> */}
              </div>
            </div>

            <div className={style.entreeMealList}>
              <div className={style.entreeMealsDetail}>
                <img
                  src={allImages.cotijaCheeseImages}
                  alt="cotijaCheeseImages"
                />

                <h4> Cotija Cheese</h4>  <LocationPopup />
                {/* <la'bel>
                  <input
                    type="checkbox"
                    name="Cotija-Cheese"
                    value={JSON.stringify({
                      name: "Cotija Cheese",
                      value: 6.99,
                      type: "cheese",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.pepperJackImages} alt="pepperJackImages" />

                <h4> Pepper Jack </h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Pepper-Jack"
                    value={JSON.stringify({
                      name: "Cotija Jack",
                      value: 6.99,
                      type: "cheese",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
            </div>
          </div>
          {/* flavour section */}
          <div className={style.toppingSection}>
            <div className={style.entreeHead}>
              <div className={style.entreeText}>
                <h2>CHOOSE YOUR FLAVORS</h2>
                {/* <p>(Additional Flavor $0.50) $ 6.99</p> */}
              </div>
            </div>

            <div className={style.entreeMealList}>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.tikkaImages} alt="tikkaImages" />

                <h4> Tikka </h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Tikka"
                    value={JSON.stringify({
                      name: "Tikka  ",
                      value: 6.99,
                      type: "flavor",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.malaoTikkaImages} alt="malaoTikkaImages" />

                <h4> Malai Tikka</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Malai-Tikka"
                    value={JSON.stringify({
                      name: "Malai Tikka  ",
                      value: 6.99,
                      type: "flavor",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.tandoriImages} alt="tandoriImages" />

                <h4> Tandoori </h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Tandoori"
                    value={JSON.stringify({
                      name: "Tandoori  ",
                      value: 6.99,
                      type: "flavor",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.curryImages} alt="curryImages" />

                <h4> Curry </h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Curry"
                    value={JSON.stringify({
                      name: "Curry",
                      value: 6.99,
                      type: "flavor",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.manchurianImages} alt="manchurianImages" />

                <h4> Manchurian</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Manchurian"
                    value={JSON.stringify({
                      name: "Manchurian",
                      value: 6.99,
                      type: "flavor",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img
                  src={allImages.mangoPickleImages}
                  alt="mangoPickleImages"
                />

                <h4>Mango Pickle </h4>  <LocationPopup />
                {/* <label>
                  <input
                    type="checkbox"
                    name="Mango-Pickle"
                    value={JSON.stringify({
                      name: "Mango Pickle",
                      value: 6.99,
                      type: "flavor",
                    })}
                    onChange={getCheckedValue}
                  />{" "}
                  Add
                </label> */}
              </div>
            </div>
          </div>
          {/* topping section */}
          <div className={style.toppingSection}>
            <div className={style.entreeHead}>
              <div className={style.entreeText}>
                <h2>
                  CHOOSE YOUR TOPPINGS <span>(any 4)</span>
                </h2>
                {/* <p>(Additional Topping $1.00) $ 6.99</p> */}
              </div>
            </div>

            <div className={style.entreeMealList}>
              <div className={style.entreeMealsDetail}>
                <img
                  src={allImages.cilantroLimeImage}
                  alt="cilantroLimeImage"
                />

                <h4> Cilantro Lime Rice</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Cilantro-Lime-Rice"
                    value={JSON.stringify({
                      name: "Cilantro Lime Rice",
                      value: 6.99,
                      type: "toppings",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.GuacamoleImage} alt="GuacamoleImage" />

                <h4>Guacamole</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Guacamole"
                    value={JSON.stringify({
                      name: "Guacamole",
                      value: 6.99,
                      type: "toppings",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.SourCreamImage} alt="SourCreamImage" />

                <h4> Sour Cream</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Sour-Cream"
                    value={JSON.stringify({
                      name: "Sour Cream",
                      value: 6.99,
                      type: "toppings",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.BlackBeansImage} alt="BlackBeansImage" />

                <h4> Black Beans </h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Black-Beans"
                    value={JSON.stringify({
                      name: "Black Beans",
                      value: 6.99,
                      type: "toppings",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.PintoBeansImage} alt="PintoBeansImage" />

                <h4> Pinto Beans</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Pinto-Beans"
                    value={JSON.stringify({
                      name: "Pinto Beans",
                      value: 6.99,
                      type: "toppings",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.OnionImage} alt="OnionImage" />

                <h4>Onion </h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Onion"
                    value={JSON.stringify({
                      name: "Onion",
                      value: 6.99,
                      type: "toppings",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.TomatoesImage} alt="TomatoesImage" />

                <h4>Tomatoes </h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Tomatoes"
                    value={JSON.stringify({
                      name: "Tomatoes",
                      value: 6.99,
                      type: "toppings",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img
                  src={allImages.ShreddedLettuceImage}
                  alt="ShreddedLettuceImage"
                />

                <h4>Shredded Lettuce</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Shredded-Lettuce"
                    value={JSON.stringify({
                      name: "Shredded Lettuce",
                      value: 6.99,
                      type: "toppings",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img
                  src={allImages.PurpleCabbageImage}
                  alt="PurpleCabbageImage"
                />

                <h4>Purple Cabbage</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Purple-Cabbage"
                    value={JSON.stringify({
                      name: "Purple Cabbage",
                      value: 6.99,
                      type: "toppings",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img
                  src={allImages.PickledJalapenosImage}
                  alt="PickledJalapenosImage"
                />

                <h4>Pickled Jalapenos</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Pickled-Jalapenos"
                    value={JSON.stringify({
                      name: "Pickled Jalapenos",
                      value: 6.99,
                      type: "toppings",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.CilantroImage} alt="CilantroImage" />

                <h4>Cilantro </h4>
                <LocationPopup />
                {/* <label>
                  <input
                    type="checkbox"
                    name="Cilantro"
                    value={JSON.stringify({
                      name: "Cilantro  ",
                      value: 6.99,
                      type: "toppings",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img
                  src={allImages.GrilledVeggiesImage}
                  alt="GrilledVeggiesImage"
                />

                <h4>Grilled Veggies</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Grilled-Veggies"
                    value={JSON.stringify({
                      name: "Grilled Veggies  ",
                      value: 6.99,
                      type: "toppings",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
              <div className={style.entreeMealsDetail}>
                <img src={allImages.AlooBhujiaImage} alt="AlooBhujiaImage" />

                <h4>Aloo Bhujia</h4>
                {/* <label>
                  <input
                    type="checkbox"
                    name="Aloo-Bhujia"
                    value={JSON.stringify({
                      name: "Aloo Bhujia  ",
                      value: 6.99,
                      type: "toppings",
                    })}
                    onChange={getCheckedValue}
                  />
                  Add
                </label> */}
              </div>
            </div>
          </div>
          {/* buttons */}
          <div className={style.menuButtonSec}>
            {/* <button className={style.CheckOutButton}>
              <SelectionPopup mealList={mealList} addOns={addOnsDetials} />
            </button> */}
            {/* <button className={style.AddCartButton} onClick={cartFunc}> */}
            <button className={style.AddCartButton} >
              Order Now
            </button>
            {/* <span> Or</span>
            <HashLink to="#addons" smooth>
              <button className={style.TryOutButton}> Try AddOns</button>
            </HashLink> */}
          </div>

          {/* sides wings salsa chips specialty Pop beverages  */}
          {/* <div className={style.sidesWingsMenu}>
            <div class="d-flex justify-content-around">
              <div class={style.mealList}>
                <img
                  className={style.menuDeal}
                  src={allImages.SidesImage}
                  alt="SidesImage"
                />
                <div className={style.mealsheading}>
                  <h3> Sides</h3>
                </div>
              </div>

              <div class={style.mealList}>
                <img
                  className={style.menuDeal}
                  src={allImages.wingsImage}
                  alt="wingsImage"
                />
                <div className={style.mealsheading}>
                  <h3>Wings</h3>
                </div>
              </div>
              <div class={style.mealList}>
                <img
                  className={style.menuDeal}
                  src={allImages.salsaChipImage}
                  alt="salsaChipImage"
                />
                <div className={style.mealsheading}>
                  <h3>Salsa & </h3>
                  <h3>Chips</h3>
                </div>
              </div>
              <div class={style.mealList}>
                <img
                  className={style.menuDeal}
                  src={allImages.specialityImage}
                  alt="specialityImage"
                />
                <div className={style.mealsheading}>
                  <h3>Specialty</h3>
                  <h3>Meals</h3>
                </div>
              </div>

              <div class={style.mealList}>
                <img
                  className={style.menuDeal}
                  src={allImages.popBevargesImage}
                  alt="popBevargesImage"
                />
                <div className={style.mealsheading}>
                  <h3>Pop</h3>
                  <h3>Beverages</h3>
                </div>
              </div>
            </div>
          </div> */}

          {/* ===============================dropdowns============================ */}

          {/* sides selection dropdown */}
          {/* <div className={style.buildMeal} id="addons">
            <div className={style.buildMealDropdownsImages}>
              <img
                src={allImages.SidesdropdwonImage}
                alt="SidesdropdwonImage"
              />
            </div>

            <div
              class="accordion accordion-flush w-100 align-self-center"
              id="accordionFlushExample"
            >
              <div class="accordion-item border rounded-8">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                    style={{
                      color: "#000000",
                      background: "rgb(243, 171, 171)",
                      borderRadius: "19px",
                    }}
                  >
                    Sides
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <h2 className={style.sidesSection}> SIDES</h2>

                    <div className={style.sidesDivInfo}>
                      <ul className={style.sidesSectionUl}>
                        {sides.map((e) => (
                          <li className={style.sidesInfo} key={e.id}>
                            <div className={style.sidesDetailsSection}>
                              <div className={style.sidesDetailsHeading}>
                                <h3>{e.name}</h3>
                                <p>lorem ipsum doler sit amet consectetuer</p>
                              </div>

                              <div>
                                <h5>
                                  <b> ${e.value}</b>
                                </h5>

                                <Counter ref={childStateRef} />

                                <button
                                  className={style.addMeal}
                                  onClick={() => getValueFromCounter(e)}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* wings selection dropdown */}

          {/* <div className={style.buildMeal}>
            <div
              class="accordion accordion-flush w-100 align-self-center"
              id="accordionFlushExample"
            >
              <div class="accordion-item border rounded-8">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                    style={{
                      color: "#000000",
                      background: "rgb(243, 171, 171)",
                      borderRadius: "19px",
                    }}
                  >
                    WINGS
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <h2 className={style.sidesSection}> WINGS</h2>
                    <div className={style.sidesDivInfo}>
                      <ul className={style.sidesSectionUl}>
                        {wings.map((e) => (
                          <li className={style.sidesInfo} key={e.id}>
                            <div className={style.sidesDetailsSection}>
                              <div className={style.sidesDetailsHeading}>
                                <h3>{e.name}</h3>
                                <p>lorem ipsum doler sit amet consectetuer</p>
                              </div>

                              <div>
                                <h5>
                                  <b> ${e.value}</b>
                                </h5>
                                <Counter ref={childStateRef} />

                                <button
                                  className={style.addMeal}
                                  onClick={() => getValueFromCounter(e)}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={style.buildMealDropdownsImages}>
              <img src={allImages.wingsImage} alt="wingsImage" />
            </div>
          </div> */}

          {/* salsa and chips dropdown */}

          {/* <div className={style.buildMeal}>
            <div className={style.buildMealDropdownsImages}>
              <img src={allImages.salsaChipImage} alt="salsaChipImage" />
            </div>
            <div
              class="accordion accordion-flush w-100 align-self-center"
              id="accordionFlushExample"
            >
              <div class="accordion-item  border rounded-8">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                    style={{
                      color: "#000000",
                      background: "rgb(243, 171, 171)",
                      borderRadius: "19px",
                    }}
                  >
                    SALSA & CHIPS
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <h2 className={style.sidesSection}> SALSA & CHIPS</h2>
                    <div className={style.sidesDivInfo}>
                      <ul className={style.sidesSectionUl}>
                        {flavor.map((e) => (
                          <li className={style.sidesInfo} key={e.id}>
                            <div className={style.sidesDetailsSection}>
                              <div className={style.sidesDetailsHeading}>
                                <h3>{e.name}</h3>
                                <p>lorem ipsum doler sit amet consectetuer</p>
                              </div>

                              <div>
                                <h5>
                                  <b> ${e.value}</b>
                                </h5>
                                <Counter ref={childStateRef} />
                                <button
                                  className={style.addMeal}
                                  onClick={() => getValueFromCounter(e)}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* SPECIALTY MEALSs dropdown */}

          {/* <div className={style.buildMeal}>
            <div
              class="accordion accordion-flush w-100 align-self-center"
              id="accordionFlushExample"
            >
              <div class="accordion-item border rounded-8">
                <h2 class="accordion-header" id="flush-headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                    style={{
                      color: "#000000",
                      background: "rgb(243, 171, 171)",
                      borderRadius: "19px",
                    }}
                  >
                    SPECIALTY MEALS
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <h2 className={style.sidesSection}> SPECIALTY MEALS</h2>
                    <div className={style.sidesDivInfo}>
                      <ul className={style.sidesSectionUl}>
                        {topping.map((e) => (
                          <li className={style.sidesInfo} key={e.id}>
                            <div className={style.sidesDetailsSection}>
                              <div className={style.sidesDetailsHeading}>
                                <h3>{e.name}</h3>
                                <p>lorem ipsum doler sit amet consectetuer</p>
                              </div>

                              <div>
                                <h5>
                                  <b> ${e.value}</b>
                                </h5>
                                <Counter ref={childStateRef} />

                                <button
                                  className={style.addMeal}
                                  onClick={() => getValueFromCounter(e)}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={style.buildMealDropdownsImages}>
              <img
                src={allImages.specialtyMealsdropdownImages}
                alt="specialtyMealsdropdownImages"
              />
            </div>
          </div> */}

          {/* pop and bverages */}

          {/*  */}
        </div>
      </div>

      {/* ===================mobile view ============== */}

      <div className={style.mobileView}>
        {/* build Section */}

        <div className={style.buildTaco}>
          <div className={style.buildTacoImage}>
            <img
              className={style.buildMenuDeal}
              src={allImages.naantacosImages}
              alt="naantacosImages"
            />
          </div>
          <div className={style.buildTacoText}>
            <h3>Build Your</h3>
            <h3>NanTaocs in | 5 Simple Steps</h3>
            <p>Lorem ipsun is simply dummy text of the printing and</p>
          </div>
        </div>

        <div className={style.mobileDropdwonSelector}>
          {/* choose your entree */}

          <div
            class="accordion accordion-flush w-100 align-self-center"
            id="accordionFlushExample"
          >
            <div class="accordion-item border rounded-8">
              <h2 class="accordion-header" id="flush-headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="true"
                  aria-controls="flush-collapseOne"
                  style={{
                    color: "#000000",
                    background: "rgb(243, 171, 171)",
                    borderRadius: "19px",
                  }}
                >
                  Choose your entree
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div className={style.sidesDivInfo}>
                    <ul className={style.sidesSectionUl}>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Naan </p>
                            <p>Tacos</p>
                            {/* <h5> $6.99</h5> */}
                          </div>

                          <img
                            src={allImages.naanmenuImages}
                            alt="naanmenuImages"
                          />

                          {/* <div>
                            <label>
                              <input
                                type="checkbox"
                                name="naan"
                                value={JSON.stringify({
                                  name: "Naantaco",
                                  value: 6.99,
                                  type: "entree",
                                })}
                                onChange={getCheckedValue}
                              />
                              Add
                            </label>
                          </div> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Naan </p>
                            <p>Burrito</p>
                            {/* <h5> $76.99</h5> */}
                          </div>
                          <img
                            src={allImages.naanBurritoImages}
                            alt="naanBurritoImages"
                          />
                          {/* <label>
                            <input
                              type="checkbox"
                              name="Burrito"
                              value={JSON.stringify({
                                name: "Naan Burrito",
                                value: 6.99,
                                type: "entree",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Tortas </p>

                            {/* <h5> $56.99</h5> */}
                          </div>
                          <img
                            src={allImages.tortosImages}
                            alt="tortosImages"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Tortas"
                              value={JSON.stringify({
                                name: "Tortas",sd
                                value: 6.99,
                                type: "entree",
                              })}
                              onChange={getCheckedValue}
                            /> 
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Chalupa </p>

                            {/* <h5> $3.99</h5> */}
                          </div>
                          <img
                            src={allImages.chalupaMenuImages}
                            alt="chalupaMenuImages"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Chalupa"
                              value={JSON.stringify({
                                name: "Chalupa",
                                value: 6.99,
                                type: "entree",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Bowl </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img src={allImages.bowlImages} alt="bowlImages" />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Bowl"
                              value={JSON.stringify({
                                name: "Bowl",
                                value: 6.99,
                                type: "entree",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>

                      <li className={style.sidesInfo}>
                            <LocationPopup />
                        {/* <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                          </div>
                        </div> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* choose your protein */}

          <div
            class="accordion accordion-flush w-100 align-self-center"
            id="accordionFlushExample"
          >
            <div class="accordion-item border rounded-8">
              <h2 class="accordion-header" id="flush-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                  style={{
                    color: "#000000",
                    background: "rgb(243, 171, 171)",
                    borderRadius: "19px",
                  }}
                >
                  Choose your Protein
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div className={style.sidesDivInfo}>
                    <ul className={style.sidesSectionUl}>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Spiced </p>
                            <p>Chicken</p>
                            {/* <h5> $6.99</h5> */}
                          </div>

                          <img
                            src={allImages.spicedChickenImage}
                            alt="spicedChickenImage"
                          />

                          {/* <div>
                            <label>
                              <input
                                type="checkbox"
                                name="Spiced-Chicken"
                                value={JSON.stringify({
                                  name: "Spiced Chicken",
                                  value: 6.99,
                                  type: "protein",
                                })}
                                onChange={getCheckedValue}
                              />{" "}
                              Add
                            </label>
                          </div> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Malai Tikka </p>
                            <p>Chicken</p>
                            {/* <h5> $76.99</h5> */}
                          </div>
                          <img
                            src={allImages.malaliTikkaChickenImage}
                            alt="malaliTikkaChickenImage"
                          />
                          {/* <label>
                            <input
                              type="checkbox"
                              name="Malai-Tikka-Chicken"
                              value={JSON.stringify({
                                name: "Malai-Tikka-Chicken",
                                value: 6.99,
                                type: "protein",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Grilled </p>
                            <p>Chicken </p>

                            {/* <h5> $56.99</h5> */}
                          </div>
                          <img
                            src={allImages.grilledChikenImage}
                            alt="grilledChikenImage"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Grilled-Chicken"
                              value={JSON.stringify({
                                name: "Grilled-Chicken",
                                value: 6.99,
                                type: "protein",
                              })}
                              onChange={getCheckedValue}
                            />
                            Add
                          </label> */}
                        </div>
                      </li>
                      {/* <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Plant-Based </p>
                            <p>Chicken </p>

                            <h5> $3.99</h5>
                          </div>
                          <img
                            src={allImages.plantBasedChikenImage}
                            alt="plantBasedChikenImage"
                          />

                          <label>
                            <input
                              type="checkbox"
                              name=" Plant-Based-Chicken"
                              value={JSON.stringify({
                                name: "Plant-Based Chicken",
                                value: 6.99,
                                type: "protein",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label>
                        </div>
                      </li> */}
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Greek </p>
                            <p>Lamb </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img
                            src={allImages.greekLambImage}
                            alt="greekLambImage"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name=" Greek-Lamb"
                              value={JSON.stringify({
                                name: "Greek-Lamb",
                                value: 6.99,
                                type: "protein",
                              })}
                              onChange={getCheckedValue}
                            />
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Panner </p>
                            <p>Tikka </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img
                            src={allImages.paneerTikkaImage}
                            alt="paneerTikkaImage"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name=" Paneer-Tikka"
                              value={JSON.stringify({
                                name: "Paneer Tikka",
                                value: 6.99,
                                type: "protein",
                              })}
                              onChange={getCheckedValue}
                            />
                            Add
                          </label> */}
                        </div>
                      </li>

                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Aloo </p>
                            <p>Tikki </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img
                            src={allImages.aaloTikkaImages}
                            alt="aaloTikkaImages"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Aloo-Tikki"
                              value={JSON.stringify({
                                name: "Aloo Tikki",
                                value: 6.99,
                                type: "protein",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>

                      <li className={style.sidesInfo}>
                        <LocationPopup />

                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* choose your cheese */}

          <div
            class="accordion accordion-flush w-100 align-self-center"
            id="accordionFlushExample"
          >
            <div class="accordion-item border rounded-8">
              <h2 class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                  style={{
                    color: "#000000",
                    background: "rgb(243, 171, 171)",
                    borderRadius: "19px",
                  }}
                >
                  Choose your cheese
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div className={style.sidesDivInfo}>
                    <ul className={style.sidesSectionUl}>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Cotija </p>
                            <p>Cheese</p>
                            {/* <h5> $6.99</h5> */}
                          </div>

                          <img
                            src={allImages.cotijaCheeseImages}
                            alt="cotijaCheeseImages"
                          />

                          {/* <div>
                            <label>
                              <input
                                type="checkbox"
                                name="Cotija-Cheese"
                                value={JSON.stringify({
                                  name: "Cotija Cheese",
                                  value: 6.99,
                                  type: "cheese",
                                })}
                                onChange={getCheckedValue}
                              />{" "}
                              Add
                            </label>
                          </div> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p> Pepper </p>
                            <p>Cheese</p>
                            {/* <h5> $76.99</h5> */}
                          </div>
                          <img
                            src={allImages.pepperJackImages}
                            alt="pepperJackImages"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Pepper-Jack"
                              value={JSON.stringify({
                                name: "Cotija Jack",
                                value: 6.99,
                                type: "cheese",
                              })}
                              onChange={getCheckedValue}
                            />
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <LocationPopup />

                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* choose your flavour */}

          <div
            class="accordion accordion-flush w-100 align-self-center"
            id="accordionFlushExample"
          >
            <div class="accordion-item border rounded-8">
              <h2 class="accordion-header" id="flush-headingfour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapsefour"
                  aria-expanded="false"
                  aria-controls="flush-collapsefour"
                  style={{
                    color: "#000000",
                    background: "rgb(243, 171, 171)",
                    borderRadius: "19px",
                  }}
                >
                  Choose your flavor
                </button>
              </h2>
              <div
                id="flush-collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingfour"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div className={style.sidesDivInfo}>
                    <ul className={style.sidesSectionUl}>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Tikka </p>

                            {/* <h5> $6.99</h5> */}
                          </div>

                          <img src={allImages.tikkaImages} alt="tikkaImages" />

                          {/* <div>
                            <label>
                              <input
                                type="checkbox"
                                name="Tikka"
                                value={JSON.stringify({
                                  name: "Tikka  ",
                                  value: 6.99,
                                  type: "flavor",
                                })}
                                onChange={getCheckedValue}
                              />{" "}
                              Add
                            </label>
                          </div> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Malai </p>
                            <p>Tikka</p>
                            {/* <h5> $76.99</h5> */}
                          </div>
                          <img
                            src={allImages.malaoTikkaImages}
                            alt="malaoTikkaImages"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Malai-Tikka"
                              value={JSON.stringify({
                                name: "Malai Tikka  ",
                                value: 6.99,
                                type: "flavor",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Tandoori </p>

                            {/* <h5> $56.99</h5> */}
                          </div>
                          <img
                            src={allImages.tandoriImages}
                            alt="tandoriImages"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Tandoori"
                              value={JSON.stringify({
                                name: "Tandoori  ",
                                value: 6.99,
                                type: "flavor",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Curry </p>

                            {/* <h5> $3.99</h5> */}
                          </div>
                          <img src={allImages.curryImages} alt="curryImages" />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Curry"
                              value={JSON.stringify({
                                name: "Curry",
                                value: 6.99,
                                type: "flavor",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Manchurian </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img
                            src={allImages.manchurianImages}
                            alt="manchurianImages"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Manchurian"
                              value={JSON.stringify({
                                name: "Manchurian",
                                value: 6.99,
                                type: "flavor",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Mango </p>
                            <p>Pickle </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img
                            src={allImages.mangoPickleImages}
                            alt="mangoPickleImages"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Mango-Pickle"
                              value={JSON.stringify({
                                name: "Mango Pickle",
                                value: 6.99,
                                type: "flavor",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <LocationPopup />

                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* choose your toppings */}

          <div
            class="accordion accordion-flush w-100 align-self-center"
            id="accordionFlushExample"
          >
            <div class="accordion-item border rounded-8">
              <h2 class="accordion-header" id="flush-headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                  style={{
                    color: "#000000",
                    background: "rgb(243, 171, 171)",
                    borderRadius: "19px",
                  }}
                >
                  Choose your topping
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingFive"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div className={style.sidesDivInfo}>
                    <ul className={style.sidesSectionUl}>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p> Cilantro Lime </p>
                            <p>Rice</p>
                            {/* <h5> $76.99</h5> */}
                          </div>
                          <img
                            src={allImages.cilantroLimeImage}
                            alt="cilantroLimeImage"
                          />
                          {/* <label>
                            <input
                              type="checkbox"
                              name="Cilantro-Lime-Rice"
                              value={JSON.stringify({
                                name: "Cilantro Lime Rice",
                                value: 6.99,
                                type: "toppings",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Guacamol </p>

                            {/* <h5> $56.99</h5> */}
                          </div>
                          <img
                            src={allImages.GuacamoleImage}
                            alt="GuacamoleImage"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Guacamole"
                              value={JSON.stringify({
                                name: "Guacamole",
                                value: 6.99,
                                type: "toppings",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Sour </p>
                            <p>Cream </p>

                            {/* <h5> $3.99</h5> */}
                          </div>
                          <img
                            src={allImages.SourCreamImage}
                            alt="SourCreamImage"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Sour-Cream"
                              value={JSON.stringify({
                                name: "Sour Cream",
                                value: 6.99,
                                type: "toppings",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Black </p>
                            <p>Beans </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img
                            src={allImages.BlackBeansImage}
                            alt="BlackBeansImage"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Black-Beans"
                              value={JSON.stringify({
                                name: "Black Beans",
                                value: 6.99,
                                type: "toppings",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Pinto </p>
                            <p>Beans </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img
                            src={allImages.PintoBeansImage}
                            alt="PintoBeansImage"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Pinto-Beans"
                              value={JSON.stringify({
                                name: "Pinto Beans",
                                value: 6.99,
                                type: "toppings",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Onion </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img src={allImages.OnionImage} alt="OnionImage" />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Onion"
                              value={JSON.stringify({
                                name: "Onion",
                                value: 6.99,
                                type: "toppings",
                              })}
                              onChange={getCheckedValue}
                            />{" "}
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Tomatoes </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img
                            src={allImages.TomatoesImage}
                            alt="TomatoesImage"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Tomatoes"
                              value={JSON.stringify({
                                name: "Tomatoes",
                                value: 6.99,
                                type: "toppings",
                              })}
                              onChange={getCheckedValue}
                            />
                            Add
                          </label> */}
                        </div>
                      </li>

                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Shredded </p>
                            <p>Lettuce </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img
                            src={allImages.ShreddedLettuceImage}
                            alt="ShreddedLettuceImage"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Shredded-Lettuce"
                              value={JSON.stringify({
                                name: "Shredded Lettuce",
                                value: 6.99,
                                type: "toppings",
                              })}
                              onChange={getCheckedValue}
                            />
                            Add
                          </label> */}
                        </div>
                      </li>

                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Purple </p>
                            <p>Cabbage </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img
                            src={allImages.PurpleCabbageImage}
                            alt="PurpleCabbageImage"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Purple-Cabbage"
                              value={JSON.stringify({
                                name: "Purple Cabbage",
                                value: 6.99,
                                type: "toppings",
                              })}
                              onChange={getCheckedValue}
                            />
                            Add
                          </label> */}
                        </div>
                      </li>

                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Pickled </p>
                            <p>Jalapenos </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img
                            src={allImages.PickledJalapenosImage}
                            alt="PickledJalapenosImage"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Pickled-Jalapenos"
                              value={JSON.stringify({
                                name: "Pickled Jalapenos",
                                value: 6.99,
                                type: "toppings",
                              })}
                              onChange={getCheckedValue}
                            />
                            Add
                          </label> */}
                        </div>
                      </li>

                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Cilantro </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img
                            src={allImages.CilantroImage}
                            alt="CilantroImage"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Cilantro"
                              value={JSON.stringify({
                                name: "Cilantro  ",
                                value: 6.99,
                                type: "toppings",
                              })}
                              onChange={getCheckedValue}
                            />
                            Add
                          </label> */}
                        </div>
                      </li>

                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Grilled </p>
                            <p>Veggie </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img
                            src={allImages.GrilledVeggiesImage}
                            alt="GrilledVeggiesImage"
                          />
                          {/* <label>
                            <input
                              type="checkbox"
                              name="Grilled-Veggies"
                              value={JSON.stringify({
                                name: "Grilled Veggies  ",
                                value: 6.99,
                                type: "toppings",
                              })}
                              onChange={getCheckedValue}
                            />
                            Add
                          </label> */}
                        </div>
                      </li>

                      <li className={style.sidesInfo}>
                        <div className={style.chooseEntree}>
                          <div className={style.chooseEntreeText}>
                            <p>Aloo </p>
                            <p>Bhujia </p>

                            {/* <h5> $12.99</h5> */}
                          </div>
                          <img
                            src={allImages.AlooBhujiaImage}
                            alt="AlooBhujiaImage"
                          />

                          {/* <label>
                            <input
                              type="checkbox"
                              name="Aloo-Bhujia"
                              value={JSON.stringify({
                                name: "Aloo Bhujia  ",
                                value: 6.99,
                                type: "toppings",
                              })}
                              onChange={getCheckedValue}
                            />
                            Add
                          </label> */}
                        </div>
                      </li>
                      <li className={style.sidesInfo}>
                        <LocationPopup />

                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* buttons */}

          <div className={style.menuButtonSec}>
            {/* <button className={style.CheckOutButton}>
              <SelectionPopup mealList={mealList} addOns={addOnsDetials} />
            </button> */}
            <button className={style.AddCartButton} >
              Order Now
            </button>
            {/* <span> Or</span>
            <HashLink to="#mobiledropDown" smooth>
              <button className={style.TryOutButton}> Try AddOns</button>
            </HashLink> */}
          </div>
        </div>

        {/* =======================llast section dropdown ========================= */}

        {/* <div className={style.buildTaco}>
          <div className={style.buildTacoImage}>
            <img
              className={style.buildMenuDeal}
              src={allImages.naantacosImages}
              alt="naantacosImages"
            />
          </div>
          <div className={style.buildTacoText}>
            <h3>Build Your</h3>
            <h3>Taste with these Add Ons</h3>
            <p>Lorem ipsun is simply dummy text of the printing and</p>
          </div>
        </div> */}

        {/* addons section mobile view */}

        {/* <div className={style.buildMeal} id="mobiledropDown">
          <div className={style.buildMealDropdownsImages}>
            <img src={allImages.SidesdropdwonImage} alt="SidesdropdwonImage" />
          </div>

          <div
            class="accordion accordion-flush w-100 align-self-center"
            id="accordionFlushExample"
          >
            <div class="accordion-item border rounded-8">
              <h2 class="accordion-header" id="flush-headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                  style={{
                    color: "#000000",
                    background: "rgb(243, 171, 171)",
                    borderRadius: "19px",
                  }}
                >
                  Sides
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                   <h2 className={style.sidesSection}> SIDES</h2>

                  <div className={style.sidesDivInfo}>
                    <ul className={style.sidesSectionUl}>
                      {sides.map((e) => (
                        <li className={style.sidesInfo} key={e.id}>
                          <div className={style.sidesDetailsSection}>
                            <div className={style.sidesDetailsHeading}>
                              <h3>{e.name}</h3>
                              <p>lorem ipsum doler sit amet consectetuer</p>
                            </div>

                            <div>
                              <h5>
                                <b> ${e.value}</b>
                              </h5>
                              <Counter ref={childStateRef} />

                              <button
                                className={style.addMeal}
                                onClick={() => getValueFromCounter(e)}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* wings selection dropdown */}

        {/* <div className={style.buildMeal}>
          <div className={style.buildMealDropdownsImages}>
            <img src={allImages.wingsImage} alt="wingsImage" />
          </div>
          <div
            class="accordion accordion-flush w-100 align-self-center"
            id="accordionFlushExample"
          >
            <div class="accordion-item border rounded-8">
              <h2 class="accordion-header" id="flush-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                  style={{
                    color: "#000000",
                    background: "rgb(243, 171, 171)",
                    borderRadius: "19px",
                  }}
                >
                  WINGS
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <h2 className={style.sidesSection}> WINGS</h2>
                  <div className={style.sidesDivInfo}>
                    <ul className={style.sidesSectionUl}>
                      {wings.map((e) => (
                        <li className={style.sidesInfo} key={e.id}>
                          <div className={style.sidesDetailsSection}>
                            <div className={style.sidesDetailsHeading}>
                              <h3>{e.name}</h3>
                              <p>lorem ipsum doler sit amet consectetuer</p>
                            </div>

                            <div>
                              <h5>
                                <b> ${e.value}</b>
                              </h5>
                              <Counter ref={childStateRef} />

                              <button
                                className={style.addMeal}
                                onClick={() => getValueFromCounter(e)}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* salsa and chips dropdown */}

        {/* <div className={style.buildMeal}>
          <div className={style.buildMealDropdownsImages}>
            <img src={allImages.salsaChipImage} alt="salsaChipImage" />
          </div>
          <div
            class="accordion accordion-flush w-100 align-self-center"
            id="accordionFlushExample"
          >
            <div class="accordion-item  border rounded-8">
              <h2 class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                  style={{
                    color: "#000000",
                    background: "rgb(243, 171, 171)",
                    borderRadius: "19px",
                  }}
                >
                  SALSA & CHIPS
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <h2 className={style.sidesSection}> SALSA & CHIPS</h2>
                  <div className={style.sidesDivInfo}>
                    <ul className={style.sidesSectionUl}>
                      {flavor.map((e) => (
                        <li className={style.sidesInfo} key={e.id}>
                          <div className={style.sidesDetailsSection}>
                            <div className={style.sidesDetailsHeading}>
                              <h3>{e.name}</h3>
                              <p>lorem ipsum doler sit amet consectetuer</p>
                            </div>

                            <div>
                              <h5>
                                <b> ${e.value}</b>
                              </h5>
                              <Counter ref={childStateRef} />
                              <button
                                className={style.addMeal}
                                onClick={() => getValueFromCounter(e)}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* SPECIALTY MEALSs dropdown */}
        {/* 
        <div className={style.buildMeal}>
          <div className={style.buildMealDropdownsImages}>
            <img
              src={allImages.specialtyMealsdropdownImages}
              alt="specialtyMealsdropdownImages"
            />
          </div>

          <div
            class="accordion accordion-flush w-100 align-self-center"
            id="accordionFlushExample"
          >
            <div class="accordion-item border rounded-8">
              <h2 class="accordion-header" id="flush-headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                  style={{
                    color: "#000000",
                    background: "rgb(243, 171, 171)",
                    borderRadius: "19px",
                  }}
                >
                  SPECIALTY MEALS
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <h2 className={style.sidesSection}> SPECIALTY MEALS</h2>
                  <div className={style.sidesDivInfo}>
                    <ul className={style.sidesSectionUl}>
                      {topping.map((e) => (
                        <li className={style.sidesInfo} key={e.id}>
                          <div className={style.sidesDetailsSection}>
                            <div className={style.sidesDetailsHeading}>
                              <h3>{e.name}</h3>
                              <p>lorem ipsum doler sit amet consectetuer</p>
                            </div>

                            <div>
                              <h5>
                                <b> ${e.value}</b>
                              </h5>
                              <Counter ref={childStateRef} />

                              <button
                                className={style.addMeal}
                                onClick={() => getValueFromCounter(e)}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* pop and bverages */}

        {/* <div className={style.buildMeal}>
          <div className={style.buildMealDropdownsImages}>
            <img src={allImages.popBevargesImage} alt="popBevargesImage" />
          </div>
          <div
            class="accordion accordion-flush w-100 align-self-center"
            id="accordionFlushExample"
          >
            <div class="accordion-item border rounded-8">
              <h2 class="accordion-header" id="flush-headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                  style={{
                    color: "#000000",
                    background: "rgb(243, 171, 171)",
                    borderRadius: "19px",
                  }}
                >
                  POP BEVERAGES
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingFive"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <h2 className={style.sidesSection}> POP BEVERAGES</h2>
                  <div className={style.sidesDivInfo}>
                    <ul className={style.sidesSectionUl}>
                      {drinks.map((e) => (
                        <li className={style.sidesInfo} key={e.id}>
                          <div className={style.sidesDetailsSection}>
                            <div className={style.sidesDetailsHeading}>
                              <h3>{e.name}</h3>
                              <p>lorem ipsum doler sit amet consectetuer</p>
                            </div>

                            <div>
                              <h5>
                                <b> ${e.value}</b>
                              </h5>
                              <Counter ref={childStateRef} />
                              <button
                                className={style.addMeal}
                                onClick={() => getValueFromCounter(e)}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default MenuSection;
