import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../components/login/login";
import Registration from "../components/login/registration";
import Checkout from "../components/Checkout/checkout.component";
import Story from "../pages/Story.js";
// import FoodSection from "../pages/FoodSelection.js";
import ProfilePage from "../pages/ProfilePage";
import FindUs from "../pages/FindUs";
import Menu from "../pages/Menu";
import Location from "../pages/location";
import LocationPage from "../pages/LocationPage";
import Tablemenu from "../pages/Tablemenu.js";

const Mainrouting = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/tacotwist" element={<Homepage />}></Route> */}
        <Route path="/menu" element={<Menu />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route
          path="/registration"
          element={<Registration />}
        ></Route>
        <Route path="/checkout" element={<Checkout />}></Route>
        <Route path="/Story" element={<Story />}></Route>
        {/* //  <Route path={`/foodselection`} element={<FoodSection />}></Route> */}
        <Route path="/profile" element={<ProfilePage />}></Route>
        <Route path="/findus" element={<FindUs />}></Route>
        <Route path="/tablemenu" element={<Tablemenu />}></Route>
        <Route path="/" element={<Location />}></Route>
        <Route
          path="/locationpage"
          element={<LocationPage />}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Mainrouting;
