import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { allImages } from "../../images";
const Slider = (props) => {
  console.log(props);
  return (
    <div>
      <OwlCarousel
        className="owl-theme"
        loop
        // margin={10}
        items={props.value}
        nav
        autoplay={true}
      >
        <div class="item">
          <img src={allImages.sliderImage1Image} alt="sliderImage1Image" />
        </div>
        <div class="item">
          <img src={allImages.sliderImage2Image} alt="sliderImage2Image" />
        </div>
        <div class="item">
          <img src={allImages.menuBannerImage} alt="menuBannerImage" />
        </div>
        <div class="item">
          <img src={allImages.menuBanner4Image} alt="menuBanner4Image" />
        </div>
        <div class="item">
          <img src={allImages.menuBanner5Image} alt="menuBanner5Image" />
        </div>
      </OwlCarousel>
    </div>
  );
};

export default Slider;
