import React from 'react';
import Profile from "../../src/components/header/profile"
import Navbar from '../components/header/header.component';

const ProfilePage = () => {
    return (
        <div>

            <Navbar />
            <Profile />
        </div>
    )
}

export default ProfilePage
