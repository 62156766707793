import { fetch } from "../fetch.services";

// const API_PATH = "https://fakestoreapi.com";

const API_PATH = "https://www.themealdb.com/api/json/v1/1";

// /filter.php?c=Seafood

// export const getRegitration = (options) => {
//   return fetch("post", API_PATH + 'registration', JSON.stringify(options), {
//     Authorization: AUTH_TOKEN,
//     'Content-Type': 'application/json',
//     Accept: 'application/json'
//   });
// }

// export const getAlldataList = () => {
//   return fetch("get", API_PATH + "/products", {}, {});
// };

export const getAlldataList = () => {
  return fetch("get", API_PATH + "/filter.php?c=Seafood", {}, {});
};

export const getCartData = (options) => {
  return fetch(
    "post",
    "https://tacotwist.projects.softpyramid.com/livewire/message" +
      "food-selection",
    JSON.stringify(options),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  );
};
