import React from "react";
import Header from "../components/header/header.component.js";
import Footer from "../components/Footer/Footer.component.js";
import LocationComponents from "../components/LocationPages/Locationcomponent.js";
const LocationPage = () => {
  return (
    <div>
      <Header />
      <LocationComponents />
      <Footer />
    </div>
  );
};

export default LocationPage;
