import "./App.css";
import GlobalProviderData from "../src/context/GlobalProviderData";
import Mainrouting from "../src/routes/index";

function App() {
  
  
  return (
    <div className="App">
      <GlobalProviderData>
        <Mainrouting />
      </GlobalProviderData>
    </div>
  );
}

export default App;
