import React, { useState } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import logo from "../../images/logo.png";
import logoIcon from "../../images/taco_twist_icon.png";
import { Link, useNavigate } from "react-router-dom";
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
} from "mdb-react-ui-kit";
import style from "./selectionPopup.module.css";
import { IoIosClose } from "react-icons/io";
import { createSearchParams } from "react-router-dom";

const LocationPopup = () => {
    const navigate = useNavigate();

    const [topRightModal, setTopRightModal] = useState(false);
    const toggleShow = () => setTopRightModal(!topRightModal);

    // const getMealNow = (e) => {
    //     const encodedData = btoa(JSON.stringify(props));
    //     navigate({
    //         pathname: `/tacotwist/checkout`,
    //         search: createSearchParams({
    //             finalList: encodedData,
    //             pickuptype: e.target.value,
    //             typecart: "menu",

    //         }).toString(),
    //     });

    //     console.log(props, " ===============finalmeallist");

    //     // setFinalMealList(finalMealObject);
    // };

    // console.log(finalMealList, "this is final meal list");
    return (
        <>
            <div className={style.orderNowBut}>
                <ul className="navbar-nav me-auto  mb-lg-0">
                    <li className={style.orderButton} onClick={() => {
                        toggleShow();
                    }}
                    >
                        Order
                    </li>
                </ul>
            </div>
            <MDBModal
                animationDirection="right"
                show={topRightModal}
                tabIndex="-1"
                setShow={setTopRightModal}
            >
                <MDBModalDialog position="top-right" side>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>
                                <Link to="/">
                                    <img src={logo} alt="logo" width={250} onClick={toggleShow} />
                                </Link>
                            </MDBModalTitle>
                            <div className={style.closeCart} onClick={toggleShow}>
                                <IoIosClose />
                            </div>
                        </MDBModalHeader>
                        <div>
                            <MDBModalBody>
                                <div className={style.popUpSelect}>
                                    <div className={style.selectSect}>Order Now</div>
                                    <Link to="/">
                                        <button
                                            className={style.carryout}
                                            value={"carryout"}
                                        // onClick={(e) => getMealNow(e)}
                                        >
                                            Select Location        </button>
                                    </Link>
                                    {/* <p className={style.delText}> OR</p>
                                    <button
                                        className={style.carryout}
                                        value={"delevery"}
                                    // onClick={(e) => getMealNow(e)}
                                    >
                                        
                                    </button> */}
                                </div>
                            </MDBModalBody>
                            <div className={style.cartButton}>
                                <img src={logoIcon} alt="logo" width={150} />
                            </div>
                        </div>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
};

export default LocationPopup;
