import React from "react";
// import style from "./location.module.css";
import { Link } from "react-router-dom";
import "./location.css";
import { allImages } from "../../images";
// {allImages.headerLogo}
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const LocationComponents = () => {
  return (
    <>

      <OwlCarousel
        className="owl-theme owl-carousel"
        loop
        // margin={10}
        items={1}
        nav
        autoplay={true}
      >
        {/* <div class="item">
          <img src={allImages.sliderImage1Image} alt="sliderImage1Image" />
        </div> */}
        <div class="item">
          <img src={allImages.sliderImage2Image} alt="sliderImage2Image" />
        </div>
        <div class="item">
          <img src={allImages.menuBannerImage} alt="menuBannerImage" />
        </div>
        <div class="item">
          <img src={allImages.menuBanner4Image} alt="menuBanner4Image" />
        </div>
        <div class="item">
          <img src={allImages.menuBanner5Image} alt="menuBanner5Image" />
        </div>
      </OwlCarousel>

      <div className="chooseLocation">
        <h2>
          Choose Location
        </h2>
      </div>
     {/* start locations */}
      {/* 1st  */}
      <div class="franchise">

      <div class="franchiseLeft">
          <img
            src={allImages.woodland}
            alt=""
          />
          <h2> Woodland, CA</h2>
          <div class="franchiseAbout">
            <p className="address">
            2115 Bronze Star Dr Ste 120, Woodland CA 95776-5618, USA</p>
            <p>
              Open Hours: 11:00 AM - 11:35 PM
            </p>
            <div class="orderButtonLocation">
              <a href="https://order.online/store/taco-twist-woodland-31141237/?hideModal=true">
                <button>
                  Order
                </button>
              </a>
            </div>
          </div>
        </div>

        <div class="franchiseLeft">
          <img
            src={allImages.frisco}
            alt=""
          />
          <h2> Frisco, TX</h2>
          <div class="franchiseAbout">
            <p className="address">
            6950 Lebanon Rd #104, Frisco, TX 75034, USA </p>
            <p>
              Open Hours: 11:00 AM - 8:40 PM
            </p>
            <div class="orderButtonLocation">
              <a href="https://order.online/store/taco-twist-frisco-27937505/?hideModal=true&pickup=true">
                <button>
                  Order
                </button>
              </a>
            </div>
          </div>
        </div>

      <div class="franchiseLeft">
    <img
      src={allImages.gateway}
      alt=""
    />
    <h2>Gateway Park Sacramento, CA</h2>
    <div class="franchiseAbout">
      <p className="address">
      4391 Gateway Park Blvd Suite 610, Sacramento, CA</p>
      <p>
      Open Hours: 11:00 AM - 11:00 PM
      </p>
      <div class="orderButtonLocation">
        <a href="https://order.online/store/taco-twist-sacramento-29592921/?hideModal=true">
          <button>
            Order
          </button>
        </a>
      </div>
    </div>
  </div>



        

        
    </div>

    <div class="franchise">

    

  <div class="franchiseLeft">
          <img
            src={allImages.yubacity}
            alt=""
          />
          <h2>Yuba City , CA</h2>
          <div class="franchiseAbout">
            <p className="address">
              706 W Onstott Frontage Rd, Yuba City, CA 95991, USA</p>
            <p>              Open Hours: 11:00 AM - 9:50 PM
            </p>
            <div class="orderButtonLocation">
              <a href="https://order.online/store/taco-twist-yuba-city-23820134/?hideModal=true&pickup=true">
                <button>
                  Order
                </button>
              </a>
            </div>
          </div>
        </div>

         <div class="franchiseLeft">
          <img
            src={allImages.frsno}
            alt=""
          />
          <h2>Fresno , CA</h2>
          <div class="franchiseAbout">
            <p className="address">
              3050 W Shaw Ave, Fresno, CA 93711, USA </p>
            <p>
              Open Hours: 11:00 AM - 9:45 PM
            </p>
            <div class="orderButtonLocation">
              <a href="https://order.online/store/taco-twist-fresno-26046469/?delivery=true&hideModal=true">
                <button>
                  Order
                </button>
              </a>
            </div>
          </div>
        </div>

        <div class="franchiseLeft">
          <img
            src={allImages.saint}
            alt=""
          />
          <h2>Saint Louis, MO</h2>
          <div class="franchiseAbout">
            <p className="address">
            15 N Euclid Avenue, St Louis, MO 63108 </p>
            <p>              Open Hours: 11:00 AM – 10:00 PM
            </p>
            <div class="orderButtonLocation">
              <a href="https://order.online/store/taco-twist-st.-louis-27937493/?hideModal=true">
              <button>
                  Order
                </button>
              </a>
            </div>
          </div>
        </div>

        

        
    </div>


    <div class="franchise">

    
        <div class="franchiseLeft">
          <img
            src={allImages.downtwon}
            alt=""
          />
          <h2>Downtown Sacramento , CA</h2>
          <div class="franchiseAbout">
            <p className="address">
              2915 K St, Sacramento, CA 95816, USA </p>
            <p>Open Hours: 11:00 AM - 12:00 AM
            </p>
            <div class="orderButtonLocation">
              <a href="https://order.online/store/taco-twist-sacramento-24852605/?hideModal=true">
                <button>
                  Order
                </button>
              </a>
            </div>
          </div>
        </div>

        <div class="franchiseLeft">
          <img
            src={allImages.elkGrove}
            alt=""
          />
          <h2>Elk Grove , CA</h2>
          <div class="franchiseAbout">
            <p className="address">
              8359 Elk Grove Florin Rd, Sacramento, CA 95829, USA </p>
            <p>
              Open Hours: 11:00 AM - 9:45 PM
            </p>
            <div class="orderButtonLocation">
              <a href="https://order.online/store/taco-twist-sacramento-25003755/?hideMod=&hideModal=true&pickup=true">
                <button>
                  Order
                </button>
              </a>
            </div>
          </div>
        </div>

    <div class="franchiseLeft">
          <img
            src={allImages.roseville}
            alt=""
          />
          <h2>Howe Ave, Sacramento</h2>
          <div class="franchiseAbout">
            <p className="address">
            1441 Howe Ave # 200, Sacramento, CA 95825, USA </p>
            <p>
            Open Hours: 11:00 AM - 10:40 PM
            </p>
            <div class="orderButtonLocation">
              <a href="https://order.online/store/taco-twist-sacramento-27612175/?hideModal=true">
                <button>
                  Order
                </button>
              </a>
            </div>
          </div>
        </div>

    </div>

    <div class="franchise">

    
        <div class="franchiseLeft">
          <img
            src={allImages.seatle}
            alt=""
          />
          <h2>  Seattle , WA</h2>
          <div class="franchiseAbout">
            <p className="address">
              4549 University Way NE, Seattle, WA 98105, USA </p>
            <p>
              Open Hours: 12:00 PM - 12:40 AM
            </p>
            <div class="orderButtonLocation">
              <a href="https://order.online/store/taco-twist-seattle-25610662/?hideModal=tru">
                <button>
                  Order
                </button>
              </a>
            </div>
          </div>
        </div>




    </div>

  




    {/* end locations */}


    



      {/* <div className="container">
        <h2 className={style.storeLocation}>Our Store Locations</h2>

        <div className={style.buildMeal}>
          <div className={style.AboutLocation} id="addons">
            <div
              class="accordion accordion-flush w-100 align-self-center"
              id="accordionFlushExample"
            >
              <div class="accordion-item border rounded-8">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                    style={{
                      color: "#000000",
                      background: "rgb(243, 171, 171)",
                      borderRadius: "19px",
                      fontSize: "1.5rem",
                    }}
                  >
                    Arizona
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className={style.sidesMain}>
                      <Link to="/tacotwist/locationPage">
                        <h2 className={style.sidesSection}> Chandler</h2>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={style.AboutLocation} id="addons2">
            <div
              class="accordion accordion-flush w-100 align-self-center"
              id="accordionFlushExample"
            >
              <div class="accordion-item border rounded-8">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                    style={{
                      color: "#000000",
                      background: "rgb(243, 171, 171)",
                      borderRadius: "19px",
                      fontSize: "1.5rem",
                    }}
                  >
                    Idaho
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className={style.sidesMain}>
                      <Link to="/tacotwist/locationPage">
                        <h2 className={style.sidesSection}> Boise</h2>{" "}
                      </Link>
                      <Link to="/tacotwist/locationPage">
                        <h2 className={style.sidesSection}> Meridian</h2>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={style.AboutLocation} id="addons3">
            <div
              class="accordion accordion-flush w-100 align-self-center"
              id="accordionFlushExample"
            >
              <div class="accordion-item border rounded-8">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                    style={{
                      color: "#000000",
                      background: "rgb(243, 171, 171)",
                      borderRadius: "19px",
                      fontSize: "1.5rem",
                    }}
                  >
                    Illinois
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className={style.sidesMain}>
                      <Link to="/tacotwist/locationPage">
                        <h2 className={style.sidesSection}> Naperville</h2>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={style.AboutLocation} id="addons4">
            <div
              class="accordion accordion-flush w-100 align-self-center"
              id="accordionFlushExample"
            >
              <div class="accordion-item border rounded-8">
                <h2 class="accordion-header" id="flush-headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                    style={{
                      color: "#000000",
                      background: "rgb(243, 171, 171)",
                      borderRadius: "19px",
                      fontSize: "1.5rem",
                    }}
                  >
                    Indiana
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className={style.sidesMain}>
                      <Link to="/tacotwist/locationPage">
                        <h2 className={style.sidesSection}> Fishers</h2>
                      </Link>
                      <Link to="/tacotwist/locationPage">
                        <h2 className={style.sidesSection}> Greenwood</h2>
                      </Link>
                      <Link to="/tacotwist/locationPage">
                        <h2 className={style.sidesSection}> Plainfield </h2>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={style.AboutLocation} id="addons5">
            <div
              class="accordion accordion-flush w-100 align-self-center"
              id="accordionFlushExample"
            >
              <div class="accordion-item border rounded-8">
                <h2 class="accordion-header" id="flush-headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                    style={{
                      color: "#000000",
                      background: "rgb(243, 171, 171)",
                      borderRadius: "19px",
                      fontSize: "1.5rem",
                    }}
                  >
                    North Carolina
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingFive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className={style.sidesMain}>
                      <Link to="/tacotwist/locationPage">
                        <h2 className={style.sidesSection}> Concord</h2>
                      </Link>
                      <Link to="/tacotwist/locationPage">
                        <h2 className={style.sidesSection}> Morrisville</h2>
                      </Link>
                      <Link to="/tacotwist/locationPage">
                        <h2 className={style.sidesSection}> Pineville</h2>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={style.AboutLocation} id="addons6">
            <div
              class="accordion accordion-flush w-100 align-self-center"
              id="accordionFlushExample"
            >
              <div class="accordion-item border rounded-8">
                <h2 class="accordion-header" id="flush-headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix"
                    aria-expanded="false"
                    aria-controls="flush-collapseSix"
                    style={{
                      color: "#000000",
                      background: "rgb(243, 171, 171)",
                      borderRadius: "19px",
                      fontSize: "1.5rem",
                    }}
                  >
                    North Carolina
                  </button>
                </h2>
                <div
                  id="flush-collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingSix"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div className={style.sidesMain}>
                      <Link to="/tacotwist/locationPage">
                        <h2 className={style.sidesSection}> Edison</h2>
                      </Link>
                      <Link to="/tacotwist/locationPage">
                        <h2 className={style.sidesSection}> Carteret</h2>
                      </Link>
                      <Link to="/tacotwist/locationPage">
                        <h2 className={style.sidesSection}> Jersey City</h2>
                      </Link>
                      <Link to="/tacotwist/locationPage">
                        <h2 className={style.sidesSection}> Cherry Hill</h2>
                      </Link>
                      <Link to="/tacotwist/locationPage">
                        <h2 className={style.sidesSection}> Kendall Park</h2>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default LocationComponents;
