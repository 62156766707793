import React, { Fragment, useEffect, useState } from "react";
import style from "./checkout.module.css";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Navbar from "../header/header.component";
import { useSearchParams } from "react-router-dom";
import OrderPopup from "./orderPopup";
import secureLocalStorage from "react-secure-storage";

const Checkout = () => {
  const [mealCheckOut, setMealcheckout] = useState([]);
  const [addOnsCheckOut, setAddOnsCheckOut] = useState([]);

  const [delevieryCharege, setDelevieryCharege] = useState(0);
  const [sumOfMeals, setSumOfMeals] = useState([]);
  const [sumOfAddons, setsumOfAddons] = useState([]);

  const [finalSumofMeals, setFinalSumofMeal] = useState(0);
  const [finalSumofAddons, setFinalSumofAddons] = useState(0);

  const [searhparams] = useSearchParams();

  const orderDetail = JSON.parse(atob(searhparams.get("finalList") || []));
  console.log(orderDetail, "===========orderdetails ============");

  const pickuptype = searhparams.get("pickuptype");

  const cartType = searhparams.get("typecart");

  console.log(cartType, "this is  ");

  useEffect(() => {
    setMealcheckout(orderDetail.mealList);
    setAddOnsCheckOut(orderDetail.addOns);
  }, []);

  // deleviery Charges
  useEffect(() => {
    if (pickuptype === "delevery") {
      setDelevieryCharege(2.65);
    } else {
      setDelevieryCharege(0);
    }
  }, [pickuptype]);

  useEffect(() => {
    if (cartType === "menu") {
      console.log("hii this is menupage");
      const mealArray = [];
      for (const i of orderDetail.mealList) {
        console.log(i.value, "ffffffffffffffffffffff");
        mealArray.push(i.value);
      }

      setSumOfMeals([...sumOfMeals, ...mealArray]);

      //  to find the sum of addons
      const newArray = [];
      for (const i of orderDetail.addOns) {
        console.log(parseInt(i.quantityOfMeal) * i.mealList.value, "addons");
        newArray.push(parseInt(i.quantityOfMeal) * i.mealList.value);
      }
      setsumOfAddons([...sumOfAddons, ...newArray]);
    }
  }, []);

  useEffect(() => {
    if (cartType === "cart") {
      console.log("hii this is cartpage");
      // to find the sum of meals

      const mealArray = [];
      for (const i of orderDetail) {
        const allSumOfMeals = i.mealList.reduce(function (prev, current) {
          return prev + +current.value;
        }, 0);
        mealArray.push(allSumOfMeals);

        setSumOfMeals([...sumOfMeals, ...mealArray]);
      }

      // to find the sum of addons
      const newArray = [];
      for (const i of orderDetail) {
        const allSumOfAddons = i.addOns.reduce(function (prev, current) {
          return prev + +current.mealList.value * current.quantityOfMeal;
        }, 0);
        newArray.push(allSumOfAddons);

        setsumOfAddons([...sumOfAddons, ...newArray]);
      }
    }
  }, []);

  useEffect(() => {
    const finalSumOfAddons = sumOfAddons.reduce(function (prev, current) {
      return prev + +current;
    }, 0);

    setFinalSumofAddons(finalSumOfAddons);
  }, [sumOfAddons]);

  useEffect(() => {
    const finalSumOfMeal = sumOfMeals.reduce(function (prev, current) {
      return prev + +current;
    }, 0);

    setFinalSumofMeal(finalSumOfMeal);
  }, [sumOfMeals]);

  const orderDone = () => {
    if (cartType) {
      secureLocalStorage.clear();
    }
  };

  const BagTotal = finalSumofAddons + finalSumofMeals;

  //  console.log(BagTotal, " this is checkout page ");

  return (
    <Fragment>
      <Navbar />
      <div className="container">
        <div className={style.checkout}>
          {pickuptype === "carryout" ? (
            <div>
              <div className={style.pickupLocation}>
                <div className="speciality">PICKUP LOCATION</div>
                <div className={style.addLocation}>
                  706 W Onstott Frontage Rd A, Yuba City, CA 95991
                </div>
              </div>
              <div className={style.pickupLocation}>
                <div className="speciality">PICKUP TIME</div>
                <button type="button" className={style.selectTime}>
                  09:15
                </button>
                <button type="button" className={style.selectTime}>
                  09:30
                </button>
                <button type="button" className={style.selectTime}>
                  09:45
                </button>
                <button type="button" className={style.selectTime}>
                  10.00
                </button>
                <button type="button" className={style.selectTime}>
                  10:15
                </button>
                <button type="button" className={style.selectTime}>
                  10:30
                </button>
                <button type="button" className={style.selectTime}>
                  10:45
                </button>
                <button type="button" className={style.selectTime}>
                  11:00
                </button>
              </div>
              <div className={style.pickupLocation}>
                <div className="speciality">PICKUP INFO</div>
                <FormControl
                  className={style.formcontrol}
                  sx={{ mt: 1, paddingBottom: 1 }}
                  variant="standard"
                >
                  <InputLabel htmlFor="standard-adornment-fname">
                    Customer Name<span style={{ color: "#ff0000" }}>*</span>
                  </InputLabel>
                  <Input id="standard-adornment-fname" type="text" />
                </FormControl>

                <FormControl
                  className={style.formcontrol}
                  sx={{ mt: 1, paddingBottom: 1 }}
                  variant="standard"
                >
                  <InputLabel htmlFor="standard-adornment-number">
                    Phone number<span style={{ color: "#ff0000" }}>*</span>
                  </InputLabel>
                  <Input id="standard-adornment-number" type="number" />
                </FormControl>

                <FormControl
                  className={style.formcontrol}
                  sx={{ mt: 1, paddingBottom: 1 }}
                  variant="standard"
                >
                  <InputLabel htmlFor="standard-adornment-email">
                    Email <span style={{ color: "#ff0000" }}>*</span>
                  </InputLabel>
                  <Input id="standard-adornment-email" type="text" />
                </FormControl>
              </div>
            </div>
          ) : (
            <div>
              <div className={style.pickupLocation}>
                <div className="speciality">BILLINGS DETAILS</div>
                <FormControl
                  className={style.formcontrol}
                  sx={{ mt: 1, paddingBottom: 1 }}
                  variant="standard"
                >
                  <InputLabel htmlFor="standard-adornment-fname">
                    Full Name<span style={{ color: "#ff0000" }}>*</span>
                  </InputLabel>
                  <Input id="standard-adornment-fname" type="text" />
                </FormControl>

                <FormControl
                  className={style.formcontrol}
                  sx={{ mt: 1, paddingBottom: 1 }}
                  variant="standard"
                >
                  <InputLabel htmlFor="standard-adornment-number">
                    Phone number<span style={{ color: "#ff0000" }}>*</span>
                  </InputLabel>
                  <Input id="standard-adornment-number" type="number" />
                </FormControl>
                <FormControl
                  className={style.formcontrol}
                  sx={{ mt: 1, paddingBottom: 1 }}
                  variant="standard"
                >
                  <InputLabel htmlFor="standard-adornment-email">
                    Email <span style={{ color: "#ff0000" }}>*</span>
                  </InputLabel>
                  <Input id="standard-adornment-email" type="text" />
                </FormControl>
                <div className={style.addLocation}>
                  <FormControl
                    className={style.formcontrol}
                    sx={{ mt: 1, paddingBottom: 1 }}
                    variant="standard"
                  >
                    <InputLabel htmlFor="standard-adornment-Address-Line-1">
                      Address Line 1 <span style={{ color: "#ff0000" }}>*</span>
                    </InputLabel>
                    <Input id="standard-adornment-Address-Line-1" type="text" />
                  </FormControl>
                </div>
                <div className={style.addLocation}>
                  <FormControl
                    className={style.formcontrol}
                    sx={{ mt: 1, paddingBottom: 1 }}
                    variant="standard"
                  >
                    <InputLabel htmlFor="standard-adornment-Landmark">
                      Landmark <span style={{ color: "#ff0000" }}>*</span>
                    </InputLabel>
                    <Input id="standard-adornment-Landmark" type="text" />
                  </FormControl>
                </div>
                <FormControl
                  className={style.formcontrol}
                  sx={{ mt: 1, paddingBottom: 1 }}
                  variant="standard"
                >
                  <InputLabel htmlFor="standard-adornment-Zipcode">
                    Zipcode <span style={{ color: "#ff0000" }}>*</span>
                  </InputLabel>
                  <Input id="standard-adornment-Zipcode" type="text" />
                </FormControl>
              </div>
            </div>
          )}
          <div className={style.pickupLocation}>
            <div className="speciality">ORDER DETAIL</div>
          </div>

          {/* ===========================from menu  page============================  */}

          {/* <div className={style.mealInfo}></div> */}
          {cartType === "cart" ? (
            <div>
              {orderDetail.map((e) => (
                <div>
                  {e.mealList.map((item) => (
                    <div className={style.mealInfo}>
                      <div className={style.mealPrice}>
                        <span> {item.type}</span>: {item.name}
                      </div>
                      <div className={style.mealPrice}> ${item.value}</div>
                    </div>
                  ))}

                  <div className={style.mealInfo}>
                    <h3>Addons Detail</h3>
                  </div>

                  <div>
                    {e.addOns.map((item) => (
                      <div className={style.mealInfo}>
                        <div className={style.mealPrice}>
                          {item.mealList.name} * {item.quantityOfMeal}
                        </div>
                        <div className={style.mealPrice}>
                          ${item.mealList.value} * {item.quantityOfMeal}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {mealCheckOut.map((item) => (
                <div className={style.mealInfo}>
                  <div className={style.mealname}>
                    <span> {item.type}</span>: {item.name}
                  </div>
                  <div className={style.mealPrice}> ${item.value} </div>
                </div>
              ))}

              <div className={style.mealInfo}>
                <h3>Addons Detail</h3>
              </div>
              {addOnsCheckOut.length !== 0 ? (
                <div>
                  {addOnsCheckOut.map((item) => (
                    <div className={style.mealInfo}>
                      <div className={style.mealPrice}>
                        {item.mealList.name} * {item.quantityOfMeal}
                      </div>
                      <div className={style.mealPrice}>
                        ${item.mealList.value} * {item.quantityOfMeal}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={style.mealInfo}>
                  <p> No Addons Detail</p>
                </div>
              )}
            </div>
          )}

          <div className={style.totalPrice}>
            <h5 className={style.priceHead}>Bag Total </h5>
            <h5 className={style.melPrice}>${BagTotal}</h5>
          </div>
          <div className={style.mealInfo}>
            <h5 className={style.priceHead}>Subtotal</h5>
            <h5 className={style.melPrice}>${BagTotal}</h5>
          </div>

          {pickuptype === "delevery" ? (
            <div className={style.mealInfo}>
              <h5 className={style.priceHead}>Delevery</h5>
              <h5 className={style.melPrice}>$ 2.65</h5>
            </div>
          ) : null}

          <div className={style.mealInfo}>
            <h5 className={style.priceHead}>Tax</h5>
            <h5 className={style.melPrice}>$1.67</h5>
          </div>
          {/* total */}
          <div className={style.payPrice}>
            <h5 className={style.priceHead}>Total</h5>
            <h5 className={style.melPrice}>
              ${BagTotal + 1.67 + delevieryCharege}
            </h5>
          </div>

          <div className={style.orderButton} onClick={orderDone}>
            <OrderPopup />
          </div>

          {/* ===========================from cart page============================  */}
        </div>
      </div>
    </Fragment>
  );
};

export default Checkout;
