import React from "react";
import { allImages } from "../../images";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Location.css";
// import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const LocationComponents = () => {
  return (
    <>
      <div className="LocationComponents">
        <img src={allImages.menuBanner4Image} alt="naantacosImages" />

        <div className="locationMenuBanner">
          <div className="container">
            <OwlCarousel
              className="owl-theme"
              loop
              // margin={10}
              items={5}
              nav
              autoplay={true}
            >
              <div class="item">
                <img
                  src={allImages.veggieLaddoImages}
                  alt="veggieLaddoImages"
                  className="sliderImage"
                />
              </div>
              <div class="item">
                <img
                  src={allImages.tikkaImages}
                  alt="tikkaImages"
                  className="sliderImage"
                />
              </div>
              <div class="item">
                <img
                  src={allImages.SourCreamImage}
                  alt="SourCreamImage"
                  className="sliderImage"
                />
              </div>
              <div class="item">
                <img
                  src={allImages.BlackBeansImage}
                  alt="BlackBeansImage"
                  className="sliderImage"
                />
              </div>
              <div class="item">
                <img
                  src={allImages.cilantroLimeImage}
                  alt="cilantroLimeImage"
                  className="sliderImage"
                />
              </div>

              <div class="item">
                <img
                  src={allImages.SourCreamImage}
                  alt="SourCreamImage"
                  className="sliderImage"
                />
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>

      {/* TESTIMONAI  */}

      <section id="review" class="review-area ptb_100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
              {/* <!-- Section Heading --> */}
              <div class="section-heading text-center">
                <span class="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                  <i class="far fa-lightbulb text-primary mr-1"></i>
                  <span class="text-primary">Customer's </span>
                  Reviews
                </span>
                <h2 class="text-capitalize">What our customers are saying</h2>
                <p class="d-none d-sm-block mt-4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Laborum obcaecati dignissimos quae quo ad iste ipsum officiis
                  deleniti asperiores sit.
                </p>
                <p class="d-block d-sm-none mt-4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Laborum obcaecati.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4 res-margin">
              {/* <!-- Single Review --> */}
              <div class="single-review card">
                {/* <!-- Card Top --> */}
                <div class="card-top p-4">
                  <div class="review-icon">
                    <i class="fas fa-star text-warning"></i>
                    <i class="fas fa-star text-warning"></i>
                    <i class="fas fa-star text-warning"></i>
                    <i class="fas fa-star text-warning"></i>
                    <i class="fas fa-star text-warning"></i>
                  </div>
                  <h4 class="text-primary mt-4 mb-3">
                    Excellent service & support
                  </h4>
                  {/* <!-- Review Text --> */}
                  <div class="review-text">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Quis nam id facilis, provident doloremque placeat eveniet
                      molestias laboriosam. Optio, esse.
                    </p>
                  </div>
                  {/* <!-- Quotation Icon --> */}
                  <div class="quot-icon">
                    <img class="avatar-md" src="#" alt="" />
                  </div>
                </div>
                {/* <!-- Reviewer -->/ */}
                <div class="reviewer media bg-gray p-4">
                  {/* <!-- Reviewer Thumb --> */}

                  {/* <!-- Reviewer Media --> */}
                  <div class="reviewer-meta media-body align-self-center ml-4">
                    <h5 class="reviewer-name color-primary mb-2">
                      Junaid Hasan
                    </h5>
                    <h6 class="text-secondary fw-6">CEO, Themeland</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 res-margin">
              {/* <!-- Single Review --> */}
              <div class="single-review card">
                {/* <!-- Card Top --> */}
                <div class="card-top p-4">
                  <div class="review-icon">
                    <i class="fas fa-star text-warning"></i>
                    <i class="fas fa-star text-warning"></i>
                    <i class="fas fa-star text-warning"></i>
                    <i class="fas fa-star text-warning"></i>
                    <i class="fas fa-star-half-alt text-warning"></i>
                  </div>
                  <h4 class="text-primary mt-4 mb-3">Nice work! Keep it up</h4>
                  {/* <!-- Review Text --> */}
                  <div class="review-text">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Quis nam id facilis, provident doloremque placeat eveniet
                      molestias laboriosam. Optio, esse.
                    </p>
                  </div>
                  {/* <!-- Quotation Icon --> */}
                  <div class="quot-icon">
                    <img
                      class="avatar-md"
                      src="assets/img/icon/quote.png"
                      alt=""
                    />
                  </div>
                </div>
                {/* <!-- Reviewer --> */}
                <div class="reviewer media bg-gray p-4">
                  {/* <!-- Reviewer Thumb --> */}

                  {/* <!-- Reviewer Media --> */}
                  <div class="reviewer-meta media-body align-self-center ml-4">
                    <h5 class="reviewer-name color-primary mb-2">
                      Junaid Hasan
                    </h5>
                    <h6 class="text-secondary fw-6">CEO, Themeland</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              {/* <!-- Single Review --> */}
              <div class="single-review card">
                {/* <!-- Card Top --> */}
                <div class="card-top p-4">
                  <div class="review-icon">
                    <i class="fas fa-star text-warning"></i>
                    <i class="fas fa-star text-warning"></i>
                    <i class="fas fa-star text-warning"></i>
                    <i class="fas fa-star text-warning"></i>
                    <i class="fas fa-star text-warning"></i>
                  </div>
                  <h4 class="text-primary mt-4 mb-3">Great support!!</h4>
                  {/* <!-- Review Text --> */}
                  <div class="review-text">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Quis nam id facilis, provident doloremque placeat eveniet
                      molestias laboriosam. Optio, esse.fgh
                    </p>
                  </div>
                  {/* <!-- Quotation Icon --> */}
                </div>
                {/* <!-- Reviewer --> */}
                <div class="reviewer media bg-gray p-4">
                  {/* <!-- Reviewer Thumb --> */}

                  {/* <!-- Reviewer Media --> */}
                  <div class="reviewer-meta media-body align-self-center ml-4">
                    <h5 class="reviewer-name color-primary mb-2">
                      Junaid Hasan
                    </h5>
                    <h6 class="text-secondary fw-6">CEO, Themeland</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* galery desing */}

      <div className="gallery">
        <h2>Gallery</h2>

        <p>This is our Gallery with lot's of dish</p>

        <ul className="galleryUl">
          <li>
            <img
              src={allImages.AlooBhujiaImage}
              width="200"
              alt="AlooBhujiaImage"
            />
            <p>Aloo Bhujia </p>
          </li>
          <li>
            <img
              src={allImages.BlackBeansImage}
              width="200"
              alt="BlackBeansImage"
            />
            <p>BlackBeans </p>
          </li>
          <li>
            <img
              src={allImages.CilantroImage}
              width="200"
              alt="CilantroImage"
            />
            <p>Cilantro</p>
          </li>
          <li>
            <img
              src={allImages.PintoBeansImage}
              width="200"
              alt="PintoBeansImage"
            />
            <p>Pinto Beans</p>
          </li>

          <li>
            <img
              src={allImages.GrilledVeggiesImage}
              width="200"
              alt="GrilledVeggiesImage"
            />
            <p>GrilledVeggie </p>
          </li>
        </ul>
      </div>

      {/* faq design */}

      <div class="content">
        <h1>FAQs</h1>

        {/* <!-- __________________________ SECTIONS ___________________________ -->  */}

        <div>
          <input type="checkbox" id="question1" name="q" class="questions" />
          <div class="plus">+</div>
          <label for="question1" class="question">
            What delivery sites do you guys use?
          </label>
          <div class="answers">
            <p>
              We use DoorDash, Uber, Grubhub & slice. The cheapest way is to
              order directly on our website at pizzatwist.com
            </p>
          </div>
        </div>
        {/* <!-- _____________________________________________________ -->  */}
        <div>
          <input type="checkbox" id="question2" name="q" class="questions" />
          <div class="plus">+</div>
          <label for="question2" class="question">
            What kind of cheese do you use for your pizza?
          </label>
          <div class="answers">
            <p>
              The kind of cheese that we use is fresh, diced mozzarella cheese.
            </p>
          </div>
        </div>
        {/* <!-- _____________________________________________________ -->   */}
        <div>
          <input type="checkbox" id="question3" name="q" class="questions" />
          <div class="plus">+</div>
          <label for="question3" class="question">
            How is Pizza Twist different than any other pizza?{" "}
          </label>
          <div class="answers">
            <p>
              Pizza Twist is different it many ways, that’s for sure! Each pizza
              is made with care and quality, you can taste every topping in
              every bite. In addition; our dough is made fresh daily, we only
              use fresh crisp vegetables, we are able to serve almost every
              dietary restriction, and our service is well above average! If you
              have any further questions or concerns, feel free to reach out to
              me via messenger!
            </p>
          </div>
        </div>
        {/* <!-- _____________________________________________________ -->   */}
        <div>
          <input type="checkbox" id="question4" name="q" class="questions" />
          <div class="plus">+</div>
          <label for="question4" class="question">
            Do you make traditional Italian pizza or only Indian?
          </label>
          <div class="answers">
            <p>We have both! traditional specialties and Indian specialties.</p>
          </div>
        </div>
      </div>

      {/* get in touch */}

      <section class="contact_us">
        <div class="container">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <div class="contact_inner">
                <div class="row">
                  <div class="col-md-10">
                    <div class="contact_form_inner">
                      <div class="contact_field">
                        <h3>Contact Us</h3>
                        <p>
                          Feel Free to contact us any time. We will get back to
                          you as soon as we can!.
                        </p>
                        <input
                          type="text"
                          class="form-control form-group"
                          placeholder="Name"
                        />
                        <input
                          type="text"
                          class="form-control form-group"
                          placeholder="Email"
                        />
                        <textarea
                          class="form-control form-group"
                          placeholder="Message"
                        ></textarea>
                        <button class="contact_form_submit">Send</button>
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-md-2">
                    <div class="right_conatct_social_icon d-flex align-items-end">
                      <div class="socil_item_inner d-flex">
                        <li>
                          <FaFacebook />
                        </li>
                        <li>
                          <FaInstagram />
                        </li>
                        <li>
                          <FaTwitter />
                        </li>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div class="contact_info_sec">
                  <h4>Contact Info</h4>
                  <div class="d-flex info_single align-items-center">
                    <i class="fas fa-headset"></i>
                    <span>+38 056 23 15 7851</span>
                  </div>
                  <div class="d-flex info_single align-items-center">
                    <i class="fas fa-envelope-open-text"></i>
                    <span>taco.info@mail.com</span>
                  </div>
                  <div class="d-flex info_single align-items-center">
                    <i class="fas fa-map-marked-alt"></i>
                    <span>706 W. Onstott Frontage Rd, Yuba City, CA 95991</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="map_sec">
        <div class="container">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <div class="map_inner">
                <h4>Find Us on Google Map</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Tempore quo beatae quasi assumenda, expedita aliquam minima
                  tenetur maiores neque incidunt repellat aut voluptas hic
                  dolorem sequi ab porro, quia error.
                </p>
                <div class="map_bind">
                  {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d471220.5631094339!2d88.04952462217592!3d22.6757520733225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1596988408134!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    frameborder="0"
                    // style="border:0;"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe> */}

                  <iframe
                    loading="lazy"
                    src="https://maps.google.com/maps?q=%203950%20W%20Ray%20Rd%2C%20Chandler%2C%20AZ%2085226&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"
                    title=" 3950 W Ray Rd, Chandler, AZ 85226"
                    aria-label=" 3950 W Ray Rd, Chandler, AZ 85226"
                    width="100%"
                    height="450"
                    frameborder="0"
                    // style="border:0;"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* about us design page  */}

      <section class="about-section">
        <div class="container">
          <div class="row">
            <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div class="inner-column">
                <div class="sec-title">
                  <span class="title">About Tacotwist</span>
                  <h2>Tacotwist Twist in Chandler, AZ</h2>
                </div>
                <div class="text">
                  Taco Twist in Chandler is located at 3950 W Ray Rd, Chandler,
                  AZ 85226. We opened our doors so that we could provide you the
                  best Taco in Chandler, AZ. Every day, we use fresh dough and
                  top it with hand-picked vegetables that are as fresh as they
                  can be.
                </div>

                <div class="text">
                  In addition to traditional Taco; we have Asian inspired
                  flavors for Taco & Wings…so go ahead, we dare you to order
                  Butter Chicken Taco or Tandoori Chicken Taco.
                </div>
                <div class="text">
                  We have an amazing range of Tacos for the entire family that
                  satisfies everyone’s taste buds and food sensitives. We have
                  Gluten-Free, Vegan, 100% Vegetarian and Halal Options.
                </div>

                <div class="text">
                  We’re here to serve you the best Taco, wings, and pasta in
                  Chandler, AZ.
                </div>
                <div class="text">
                  Our Taco in Chandler, AZ has a quick delivery and you get
                  delicious, indulging Taco at your doorstep. Call us at (602)
                  675-6777 to find out how we can make your special day even
                  better.
                </div>
              </div>
            </div>

            {/* <!-- Image Column --> z */}
            <div class="image-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column wow fadeInLeft">
                <figure class="image-1">
                  {/* <a href="#" class="lightbox-image" data-fancybox="images"> */}
                  <img title="Tacotwist" src={allImages.locationimage} alt="" />
                  {/* </a> */}
                </figure>
              </div>
            </div>
          </div>
          <div class="sec-title">
            <span class="title">Our Future Goal</span>
            <h2>Chandler, AZ pizza delivery begins with Pizza Twist</h2>
          </div>
          <div class="text">
            Chandler is a fantastic place to call home. This is made much better
            by the plethora of pizza joints.
          </div>
          <div class="text">
            Pizza is popular among children and young adults because it is
            simple to eat, and it is also popular among parents because it
            allows them to force their children to eat veggies as they eat (just
            add mushrooms, peppers, spinach, and anything else that sneaks into
            the house).
          </div>
          <div class="text">
            No one can resist the taste of the Hot and Delicious Pizza, which is
            why it is so popular with families.
          </div>

          <div class="sec-title">
            <h2>Wholesome Experience of Pizza, Salads, Desserts and more</h2>
          </div>
          <div class="text">
            Pizza Twist in Chandler, AZ serves more than just pizza.
          </div>
          <div class="text">
            There are also wings, Pasta, Breadsticks, Salads and sides on the
            menu.
          </div>
          <div class="text">
            As a result, you may rest assured that we are well-versed in our
            field.
          </div>
          <div class="text">
            We understand that there are other pizza businesses in Chandler, AZ,
            but we assure you will not be disappointed when you try our soft,
            excellent crust with the ideal balance of sauce and cheese, as well
            as whatever toppings you like!
          </div>
        </div>
      </section>
    </>
  );
};

export default LocationComponents;
