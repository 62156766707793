import React, { useEffect, useState } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import logo from "../../images/logo.png";
import logoIcon from "../../images/taco_twist_icon.png";
import { Link } from "react-router-dom";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";
import style from "./cart.module.css";
import { FaCartArrowDown } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { createSearchParams } from "react-router-dom";

const Cart = () => {
  const navigate = useNavigate();

  const [topRightModal, setTopRightModal] = useState(false);
  const toggleShow = () => setTopRightModal(!topRightModal);

  const [sumOfMeals, setSumOfMeals] = useState([]);
  const [sumOfAddons, setsumOfAddons] = useState([]);

  const [finalSumofMeals, setFinalSumofMeal] = useState(0);
  const [finalSumofAddons, setFinalSumofAddons] = useState(0);

  const cartLocalData = secureLocalStorage.getItem("allCartData");

  const getMealNow = (e) => {
    const encodedData = btoa(JSON.stringify(cartLocalData));

    navigate({
      pathname: `/checkout`,
      search: createSearchParams({
        finalList: encodedData,
        pickuptype: e.target.value,
        typecart: "cart",
      }).toString(),
    });
  };

  const removeMealFromCart = (e) => {
    const updateList = cartLocalData.filter((item) => item !== e);
    secureLocalStorage.setItem("allCartData", updateList);
  };

  useEffect(() => {
    if (cartLocalData) {
      // to find the sum of meals

      const mealArray = [];
      for (const i of cartLocalData) {
        const allSumOfMeals = i.mealList.reduce(function (prev, current) {
          return prev + +current.value;
        }, 0);
        mealArray.push(allSumOfMeals);

        setSumOfMeals([...sumOfMeals, ...mealArray]);
      }

      // to find the sum of addons
      const newArray = [];
      for (const i of cartLocalData) {
        const allSumOfAddons = i.addOns.reduce(function (prev, current) {
          return prev + +current.mealList.value * current.quantityOfMeal;
        }, 0);
        newArray.push(allSumOfAddons);

        setsumOfAddons([...sumOfAddons, ...newArray]);
      }
    }
  }, []);

  useEffect(() => {
    const finalSumOfAddons = sumOfAddons.reduce(function (prev, current) {
      return prev + +current;
    }, 0);

    setFinalSumofAddons(finalSumOfAddons);
  }, [sumOfAddons]);

  useEffect(() => {
    const finalSumOfMeal = sumOfMeals.reduce(function (prev, current) {
      return prev + +current;
    }, 0);

    setFinalSumofMeal(finalSumOfMeal);
  }, [sumOfMeals]);

  // console.log(finalSumofMeals, finalSumofAddons, sumOfMeals, sumOfAddons);

  const BagTotal = finalSumofMeals + finalSumofAddons;

  return (
    <>
      <div className="headingIcon">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li
            className="nav-item"
            onClick={() => {
              toggleShow();
            }}
          >
            <FaCartArrowDown /> Cart
            {cartLocalData && cartLocalData.length > 0 ? (
              <span>({cartLocalData.length}) </span>
            ) : null}
          </li>
        </ul>
      </div>
      <MDBModal
        animationDirection="right"
        show={topRightModal}
        tabIndex="-1"
        setShow={setTopRightModal}
      >
        <MDBModalDialog position="top-right" side>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                <Link to="/">
                  <img src={logo} alt="logo" width={250} onClick={toggleShow} />
                </Link>
              </MDBModalTitle>
              <div className={style.closeCart} onClick={toggleShow}>
                <IoIosClose />
              </div>
            </MDBModalHeader>
            {cartLocalData !== null && cartLocalData.length > 0 ? (
              <div>
                <div className={style.selectedMeal}>
                  {cartLocalData.map((e) => (
                    <div>
                      <h4>Meal Details</h4>
                      {e.mealList.length !== 0 ? (
                        <div>
                          {e.mealList.map((item) => (
                            <div className={style.mealsList}>
                              <div>{item.name}</div>
                              <div> ${item.value}</div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className={style.mealInfo}>
                          <p> No Meal Detail</p>
                        </div>
                      )}

                      <h4> Addons Details</h4>

                      {e.addOns.length !== 0 ? (
                        <div>
                          {e.addOns.map((item) => (
                            <div className={style.mealsList}>
                              <div>
                                {item.mealList.name} * {item.quantityOfMeal}
                              </div>
                              <div>
                                ${item.mealList.value}* {item.quantityOfMeal}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className={style.mealInfo}>
                          <p> No Addons Detail</p>
                        </div>
                      )}

                      <button
                        className={style.customizeButtom}
                        value={e}
                        onClick={() => {
                          removeMealFromCart(e);
                          toggleShow();
                        }}
                      >
                        REMOVE
                      </button>
                      <button className={style.customizeButtom}>EDIT</button>
                    </div>
                  ))}
                  <Link to="/menu">
                    <button className={style.addMenuButton}>
                      ADD ANOTHER MENU ITEM
                    </button>
                  </Link>
                  <div className={style.napkinsCheck}>
                    <div>Include Napkins & Utensils</div>
                    <div>
                      <input type="checkbox" defaultChecked={true} />
                    </div>
                  </div>
                  <div className={style.mealsHead}>
                    <div>Cart Total</div>
                    <div>$ {BagTotal} </div>
                  </div>
                </div>
                <div className={style.finalTotal}>
                  <button
                    className={style.checkoutButton}
                    value={"delevery"}
                    onClick={(e) => {
                      getMealNow(e);
                      toggleShow();
                    }}
                  >
                    DELEVERY
                  </button>
                  <button
                    className={style.checkoutButton}
                    value={"carryout"}
                    onClick={(e) => {
                      getMealNow(e);
                      toggleShow();
                    }}
                  >
                    CARRYOUT
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <MDBModalBody>
                  <div>
                    <p className="speciality">YOUR BAG IS EMPTY</p>
                    <p className={style.aboutCart}>
                      Start an order for yourself, or you and your friends.
                      Start an order for yourself, or you and your friends.
                    </p>
                  </div>
                </MDBModalBody>
                <div className={style.cartButton}>
                  <img src={logoIcon} alt="logo" width={150} />
                  <Link to="/menu">
                    <button
                      type="button"
                      className={style.orderButton}
                      onClick={toggleShow}
                    >
                      Start Your Order
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default Cart;
