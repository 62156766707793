import React, { Fragment } from "react";
import style from "./Story.module.css";
import { allImages } from "../../images";
import Slider from "../Loader/Slider";

const OurStory = () => {
  return (
    <Fragment>
      <div className="container mt-5">
        <div className={style.ourStory}>
          <div className={style.storySection}>
            <div className={style.storyText}>
              <h2>OUR STORY</h2>
              <p>
                <b>
                  starts with a dream of bringing a ‘Spectacolar’ Twist for
                  people
                </b>
              </p>

              <p>
              Fresh, delicious, and made to order Tacos in a variety of flavors, customized especially for you. Inspired by the love for fusion food, we decided to create a taco place that is #SpecTACOlarly yours!
              </p>
            </div>
            <div>
              <h2>SUPPORT LOCAL</h2>

              <p>
                We're doing more than just serving you fresh and delicious
                tacos. We source our ingredients locally to support other small
                businesses in the community. Taco Twist takes great pride in
                creating jobs for local people. If you’re passionate and looking
                to make a difference in your community, become a part of our
                family .
              </p>
            </div>
          </div>
          <div className={style.storySection}>
            <img src={allImages.ourStoryimage} alt="ourstoryImage" />
          </div>
          <div className={style.storySection}>
            <h2>OUR PHILOSOPHY</h2>

            <p>
              Family and friends that eat together, stay together. Taco Twist
              crafts healthy yet tasty tacos and more by using distinctive
              ingredients and flavors that perfectly fits the taste palate of
              every person who visits us.
            </p>
          </div>
        </div>
      </div>

      <div className={style.storyBottom}>
        <div className={style.storyBottomcontnet}>
          <h2>Gallery</h2>
          <p>
            A glimpse of mouth-watering goodness, just enough to make you drool!
          </p>
        </div>
      </div>

      <Slider value={5} />
    </Fragment>
  );
};

export default OurStory;
