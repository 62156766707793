import React from "react";
import style from "./findus.module.css";
import { SlLocationPin } from "react-icons/sl";
import { MdOutlineEmail } from "react-icons/md";
import { SlCallIn } from "react-icons/sl";

const FindusComponent = () => {
  return (
    <>
      <div className="container">
        <div className={style.findUs}>
          <h2>CONTACT US</h2>
          <p>Have a question? You are in the right place. </p>
          {/* <ul>
            <li>WORKING TIME </li>
            <li>Monday - Friday .......... 09:00 - 23:00</li>
            <li>Saturday ....................... 11:00 - 01:00</li>
            <li>Sunday ......................... 12:00 - 23:00</li>
          </ul> */}

          <div className={style.contactus}>
            <div className={style.innerContact}>
              {/* <SlCallIn /> */}
              {/* <h4>CALL US </h4>
              <p>+38 056 23 15 7851</p> */}
            </div>
            <div className={style.innerContact}>
              {/* <SlLocationPin />  */}
              {/* <h4>FIND US </h4>
              <p>706 W. Onstott Frontage Rd, Yuba City, CA 95991</p> */}
            </div>
            <div className={style.innerContact}>
              {/* <MdOutlineEmail /> */}
              {/* <h4>MAIL US </h4>
              <p>tacotwist.info@mail.com</p> */}
            </div>
          </div>

          <div class="row align-items-center">
            <div class={style.findUsSec}>
              <form>
                <div class="row mb-2">
                  <div class="col-lg-6 mb-3 mb-lg-0">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="First Name*"
                      aria-label="First name"
                      required
                    />
                  </div>
                  <div class="col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Last Name*"
                      aria-label="Last name"
                      required
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 mb-3 mb-lg-0">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email*"
                      aria-label="Email"
                      required
                    />
                  </div>
                  <div class="col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Telephone*"
                      aria-label="Telephone"
                      required
                    />
                  </div>
                </div>
                <div class="my-3">
                  <textarea
                    class="form-control"
                    placeholder="Message"
                    id="message"
                  ></textarea>
                </div>
                <div class="text-center mt-4">
                  <button type="submit" class={style.formButton}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindusComponent;
