import React, { Fragment, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import style from "./login.module.css";
import logoImage from "../../images/logo.png";
import { Link } from "react-router-dom";
import { GlobalData } from '../../context/GlobalProviderData';
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";



const Login = () => {
    const navigate = useNavigate();


    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const {  setuseData } = useContext(GlobalData)

    // emailvalidate function

    const validateEmail = mail => {
        if (
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail)
        ) {
            return true;
        }
        return false;
    };


    //  main registration function


    const login = async (e) => {

        e.preventDefault();
        // console.log('show email' + email, 'password ' + password);
        if (email.trim() === '') {
            alert('email field is empty');
            return;
        } else if (!validateEmail(email.trim())) {
            alert('Invalid email');
            return;
        } else if (password.trim() === '' || password.trim().length < 8) {
            alert('Password is incorrect');
            return;
        }

        const LoginUser = { email: email, password: password };

        // await postData(loginUser, siteConfig.LOGIN)
        //     .then(response => response.json())
        //     .then(data => {
        //         console.log(data, 'eeeee')
        //         if (data.statusCode === 200) {
        //             // setIsLoggedIn(true)
        //             
        //             localStorage.setItem(

        //                 siteConfig.ACCESS_TOKEN_KEY,
        //                 data.token.accessToken,
        //             );
        //             localStorage.setItem('fullName', data?.user?.fullname);
        //         } else {
        //             setError(true);
        //         }
        //     })
        //     .catch(error => {
        //         console.log('show the error', error);
        //     });

        console.log(LoginUser);

        // setisLogin(true);

        setuseData(LoginUser.email)

        // console.log(islogin);

        console.log('you are on login page');
        navigate("/");
    };
    return (
        <Fragment>
            <div className={style.logincontainer}>
                <div>
                    <Link to="/">
                        <img src={logoImage} alt='logoimage' className={style.logoImage} />
                    </Link>

                    <p className={style.signIn}>
                        Sign In
                    </p>
                </div>

                <form>
                    <Box sx={{ maxWidth: 360, mx: "auto", display: 'flex', flexDirection: 'column', }}>

                        <FormControl sx={{ m: 1, }} className={style.inputWidth} variant="standard">
                            <InputLabel htmlFor="standard-adornment-email">Email</InputLabel>
                            <Input id="standard-adornment-email" type="text" onChange={e => {
                                setEmail(e.target.value);
                                setError('');
                            }} />
                        </FormControl >

                        <FormControl className={style.inputWidth} sx={{
                            m: 1,
                            borderBottom: 1,
                            paddingBottom: 4,
                            borderColor: 'grey.500'
                        }} variant="standard">


                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                            <Input
                                id="standard-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={e => {
                                    setPassword(e.target.value);
                                    setError('');
                                }}
                                required
                            />
                            <div style={{ display: 'flex', alignItems: 'flex-end', }}>
                                <span style={{ color: '#ff0000' }}>{error}</span>
                            </div>
                            <button type="submit" className={style.signInBut} onClick={(e) => login(e)} > Sign In  </button>
                        </FormControl>
                    </Box >
                </form>
                <p className={style.signIn}>
                    NOT A MEMBER?
                </p>
                <Link to="/registration"><button type='button' className={style.signInBut}>Create an Account</button></Link>
                <p className='speciality '>
                    Need Help? Chat with Pepper
                </p>
            </div>
        </Fragment >

    );
}

export default Login;