import style from "./footer.module.css";

import React from "react";

import { FaFacebook, FaInstagram, FaTwitter, FaTiktok } from "react-icons/fa";
import { allImages } from "../../images";
const Footer = () => {
  return (
    <div className={style.footer}>
      <div className="container">
        <div className={style.footercontent}>
          <div className={style.sub}>
            <ul>
              <li>
                <img src={allImages.footerlogo} alt="logo" />
              </li>
              <li>
                Spectacular, delicious and specifically customized soft tacos to
                satisfy your unique cravings. We don’t taco bout’ it a lot, but we add a unique twist to your taste buds.

              </li>
            </ul>
            {/* <ul>
              <li>RESTAURANT TIMING</li>
              <li>Monday - Friday ..... 09:00 - 23:00</li>
              <li>Saturday ..... 11:00 - 01:00</li>
              <li>Sunday ..... 12:00 - 23:00</li>
            </ul> */}
            <ul>
              <li>CONTACT US</li>
              {/* <li>706 W. Onstott Frontage Rd, Yuba City, CA 95991</li> */}
              {/* <li>+38 056 23 15 7851</li> */}
              <li>taco.info@mail.com</li>
            </ul>

            <ul>
              <li>FOLLOW US ON:</li>





              <a href="https://www.facebook.com/tacotwistus/">

                <li className={style.icondes}>
                  <FaFacebook />
                </li> </a>
              <a href="https://www.tiktok.com/@tacotwist">    <li className={style.icondes}>
                <FaTiktok />
              </li> </a>
              <a href="https://www.instagram.com/taco_twist/">  <li className={style.icondes}>
                <FaInstagram />
              </li> </a>
            </ul>
          </div>

          <div className={style.copywrite}>
            Tacotwist © 2024, All rights reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
