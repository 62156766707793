import React from "react";
import Header from "../components/header/header.component.js";
import Footer from "../components/Footer/Footer.component.js";
import MenuSection from "../components/MenuSection/MenuSection.js";
import Helmet from "helmet";

const Menu = () => {
  return (
    <div>


      <Helmet>
        <title>Best Taco Near Me | Taco Twist Menu
        </title>
        <meta name="description" content="Craving the best taco near you? Explore the mouthwatering Taco Twist menu at tacotwist.com. Delight in a flavorful array of taco creations for an unforgettable dining experience.

" />


      </Helmet>
      <Header />
      <MenuSection />
      <Footer />
    </div>
  );
};

export default Menu;
