import React, { Fragment } from "react";
import Navbar from "../components/header/header.component";
import Footer from "../components/Footer/Footer.component.js";
import FindusComponent from "../components/FindUs/findus.component.js";
import { Helmet } from "react-helmet";

const FindUs = () => {
  return (
    <Fragment>

      <Helmet>
        <title>Taco Place Near Me | Taco Twist</title>
        <meta name="description" content="Discover the nearest taco place with Taco Twist! Indulge in flavorful delights at tacotwist.com. Explore our menu for a tasty taco experience right around the corner!
" />


      </Helmet>

      <Navbar />
      <FindusComponent />
      <Footer />
    </Fragment>
  );
};

export default FindUs;
