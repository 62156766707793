import React, { Fragment } from "react";
import Navbar from "../components/header/header.component";
import Footer from "../components/Footer/Footer.component.js";
import TablemenuComponent from "../components/Tablemenu/Tablemenu.component.js";
import menutableimageone from "../images/menutableone.jpeg";
import menutableimagetwo from "../images/menutabletwo.jpeg";
import menutableimagethree from "../images/menutablethree.jpeg";
import { Helmet } from "react-helmet";

const Tablemenu = () => {
  return (
    <Fragment>

      <Helmet>
        <title>Tablemenu | Taco Twist</title>
        <meta name="description" content="Discover the nearest taco place with Taco Twist! Indulge in flavorful delights at tacotwist.com. Explore our menu for a tasty taco experience right around the corner!
" />


      </Helmet>

      <Navbar />
      <div>
      <img src={menutableimageone} alt="Menu Image" />
      </div>
      <br></br>
      <br></br>
     
      <div>
      <img src={menutableimagetwo} alt="Menu Image" />
      </div>
      <br></br>
      <br></br>
      
      <div>
      <img src={menutableimagethree} alt="Menu Image" />
      </div>
      <br></br>
      <br></br>
      {/* <TablemenuComponent /> */}
      <Footer />
    </Fragment>
  );
};

export default Tablemenu;
