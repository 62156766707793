import React, { useContext } from "react";
import "./header.css";
import { FaUser } from "react-icons/fa";
import Cart from "./cart.js";
import { Link } from "react-router-dom";
import { GlobalData } from "../../context/GlobalProviderData";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import { allImages } from "../../images";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";

const Navbar = () => {
  const { useData } = useContext(GlobalData);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    console.log("profile is clicked");
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <div className="container nav_bg">
        <nav className="navbar navbar-expand-lg navbar-light">
          <Link className="navbar-brand" to="/">
            <img src={allImages.headerLogo} alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto   mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/story">
                  Our Story
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/menu">
                  Our Menu
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/findus">
                  Find Us
                </Link>
              </li>
              <a href="https://www.facebook.com/tacotwistus/">

                <li className="socialLinks nav-item"  >

                  <FaFacebook />
                </li>
              </a>
              <a href="https://www.instagram.com/taco_twist/">
                <li className="socialLinks nav-item">
                  <FaInstagram />
                </li>
              </a>
              <a href="https://www.tiktok.com/@tacotwist">
                <li className="socialLinks nav-item">
                  <FaTiktok />
                </li>
              </a>
            </ul>
          </div>
          {/* <div className="headingIcon">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex flex-column ">
              <li>
                <Cart />
              </li>

              {useData ? (
                <li className="nav-item">
                  <div className="dashboard">
                    <FaUser />
                    <Stack direction="row" spacing={2}>
                      <Button
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={open ? "composition-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        sx={{
                          color: "#e31e31",
                          fontSize: "15px",
                          paddingTop: "0",
                        }}
                        onClick={handleToggle}
                      >
                        Profile
                      </Button>
                      <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom-start"
                                  ? "left top"
                                  : "left bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  autoFocusItem={open}
                                  id="composition-menu"
                                  aria-labelledby="composition-button"
                                  onKeyDown={handleListKeyDown}
                                >
                                  <Link to="/tacotwist/profile">
                                    <MenuItem>Profile</MenuItem>
                                  </Link>

                                  <MenuItem onClick={handleClose}>
                                    Logout
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </Stack>
                  </div>
                </li>
              ) : (
                <li>
                  <Link to="/tacotwist/login">
                    <FaUser /> Sign Up
                  </Link>
                </li>
              )}
            </ul>
          </div> */}
        </nav>
      </div>
    </>
  );
};

export default Navbar;
