import React, { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import style from "./login.module.css";
import logoImage from "../../images/logo.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

const Registration = () => {
  // const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [offers, setOffers] = useState("yes");
  const [password, setPassword] = useState("");
  const [number, setNumber] = useState("");
  const [error, setError] = useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // emailvalidate function

  // console.log(offers)

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  //  main registration function

  const signUp = async (e) => {
    e.preventDefault();
    if (fname.trim() === "") {
      setError("Please enter your first name");
      return;
    }
    if (lname.trim() === "") {
      setError("Please enter your  last name");
      return;
    }

    if (offers.trim() === "") {
      setError("Please select your text type");
      return;
    }

    if (email.trim() === "") {
      setError("Please enter your email");
      return;
    } else if (!validateEmail(email.trim())) {
      setError("Please enter a valid email address");
      return;
    } else if (password.trim() === "" || password.trim().length < 8) {
      setError("Minimum 8 character lenght required");
      return;
    } else if (number.trim() === "" || number.trim().length !== 10) {
      setError("please enter a valid number ");
      return;
    }
    const registerUser = {
      fname: fname,
      lname: lname,
      email: email,
      password: password,
      number: number,
      offers: offers,
    };

    console.log(registerUser);

    // await postData(registerUser, siteConfig.SIGN_UP)
    //     .then(response => response.json())
    //     .then(data => {
    //         console.log(data);
    //         if (data.statusCode === 200) {
    //            navigate('/');
    //             localStorage.setItem(
    //                 siteConfig.ACCESS_TOKEN_KEY,
    //                 data.token.accessToken,
    //             );
    //             localStorage.setItem('fullName', data?.user?.fullname)
    //         } else {
    //             setError('email already exists');
    //         }
    //     })
    //     .catch(error => {
    //         console.log('show the error', error);
    //     });
  };

  return (
    <Fragment>
      <div className={style.logincontainer}>
        <div>
          <Link to="/">
            <img src={logoImage} alt="logoimage" className={style.logoImage} />
          </Link>
          <p className={style.signIn}>CREATE AN ACCOUNT</p>
        </div>

        <Box
          sx={{
            maxWidth: 360,
            mx: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p>
            Create an account so you can get rewards and order your favorites
            even faster.
          </p>

          <FormControl
            className={style.inputWidth}
            sx={{ mt: 1, paddingBottom: 1 }}
            variant="standard"
          >
            <InputLabel htmlFor="standard-adornment-fname">
              First Name<span style={{ color: "#ff0000" }}>*</span>
            </InputLabel>
            <Input
              id="standard-adornment-fname"
              type="text"
              required
              onChange={(e) => {
                setFname(e.target.value);
                setError("");
              }}
            />
          </FormControl>

          <FormControl
            className={style.inputWidth}
            sx={{ mt: 1, paddingBottom: 1 }}
            variant="standard"
          >
            <InputLabel htmlFor="standard-adornment-lname">
              Last name<span style={{ color: "#ff0000" }}>*</span>
            </InputLabel>
            <Input
              id="standard-adornment-lname"
              type="text"
              required
              onChange={(e) => {
                setLname(e.target.value);
                setError("");
              }}
            />
          </FormControl>

          <FormControl
            className={style.inputWidth}
            sx={{ mt: 1, paddingBottom: 1 }}
            variant="standard"
          >
            <InputLabel htmlFor="standard-adornment-email">
              Email <span style={{ color: "#ff0000" }}>*</span>
            </InputLabel>
            <Input
              id="standard-adornment-email"
              type="text"
              required
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
            />
          </FormControl>

          <FormControl
            className={style.inputWidth}
            sx={{ mt: 1, paddingBottom: 1 }}
            variant="standard"
          >
            <InputLabel htmlFor="standard-adornment-password">
              Password<span style={{ color: "#ff0000" }}>*</span>
            </InputLabel>
            <Input
              id="standard-adornment-password"
              required
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={(e) => {
                setError("");
                setPassword(e.target.value);
              }}
            />
          </FormControl>

          <FormControl
            className={style.inputWidth}
            sx={{ mt: 1, paddingBottom: 1 }}
            variant="standard"
          >
            <InputLabel htmlFor="standard-adornment-number">
              Mobile number<span style={{ color: "#ff0000" }}>*</span>
            </InputLabel>
            <Input
              id="standard-adornment-number"
              type="number"
              onChange={(e) => {
                setNumber(e.target.value);
                setError("");
              }}
              required
            />
          </FormControl>

          <p className={style.mobileText}>
            Enter a valid mobile number that can accept SMS messages. This will
            be used for two-step verification for account access, and for
            order-related notifications
          </p>
          <div>
            <p style={{ textAlign: "left" }}>
              Recieve text offers and promotions?
              <span style={{ color: "#ff0000" }}>*</span>
            </p>

            <FormControl sx={{ display: "block" }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Yes"
                name="radio-buttons-group"
                sx={{ flexDirection: "row" }}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio />}
                  label="Yes"
                  onClick={() => {
                    setOffers("yes");
                  }}
                />
                <FormControlLabel
                  value="No"
                  control={<Radio />}
                  label="No"
                  onClick={() => {
                    setOffers("no");
                  }}
                />
              </RadioGroup>
            </FormControl>
          </div>

          <FormControl
            className={style.inputWidth}
            sx={{ mt: 1, paddingBottom: 1 }}
            variant="standard"
          >
            {/* <InputLabel htmlFor="standard-adornment-date"> Birthday MM/DD</InputLabel> */}
            <Input id="standard-adornment-date" type="date" />
          </FormControl>

          <p>so we know when to celebrate</p>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <span style={{ color: "#ff0000" }}>{error}</span>
          </div>

          <button
            type="button"
            className={style.signInBut}
            onClick={(e) => signUp(e)}
          >
            {" "}
            Create an Account{" "}
          </button>
        </Box>

        <p style={{ paddingTop: 15 }}>
          Already a member?
          <Link to="/login">
            {" "}
            <span className="speciality"> Sign In </span>
          </Link>
        </p>

        {/* <p className='speciality '>
                    Need Help? Chat with Pepper
                </p> */}
      </div>
    </Fragment>
  );
};

export default Registration;
